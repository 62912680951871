<div fxLayout="row wrap" fxLayoutAlign="start center" class="page-content landing-page-bg">
	<div fxLayout="column" fxFlex="50" fxLayoutAlign="start start" class="pl-5">
		<div fxLayout="row wrap" class="tab-content-header mb-4">
			<h1>Welcome to L2L Open Data Platform</h1>
		</div>
		<div fxLayout="column" fxFlex.lt-md="35" fxFlex.md="35" class="tab-content-body">
			<p>Millions of new devices, including electric vehicles (EVs), renewables like solar and wind power, 
				batteries and smart devices, will challenge the way we think about our energy grid.
			</p>
			<p>
				Join the L2L platform to shape the energy system future, 
				lower your carbon footprint and maximize your investment in Distributed Energy Resources.</p>
		</div>
		<div fxLayout="row" class="mt-3">
			<button type="button" class="btn btn-primary btn-lg btn-get-start p-2" (click)="navigateToLogin()">Get
				Started <div class="arrow"><span>&#8594;</span></div></button>
		</div>
	</div>
</div>