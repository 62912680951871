<app-page-loader *ngIf="dataLoadPending" class="app-loader"></app-page-loader>

<div fxFill fxLayout="row wrap" fxLayoutAlign="space-between start">
	<div fxFlex="100" fxLayout="row wrap" class="tile-card">
		<div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-between start" class="tile-card-header">
			<h1 fxFlex.lt-md="100"></h1>
			<div fxFlex.lt-md="100" *ngIf="summaryView !== 'Live'" fxLayout="row" fxLayoutAlign="end center">
				<button mat-mini-fab class="blue-button left rounded-button mr-2" (click)="viewPreviousPeriod()"
					[disabled]="disablePreviousPeriod">
					<i class="ace-icon fa fa-chevron-left ace-icon-align"
						matTooltip="{{disablePreviousPeriod? 'You are viewing the first period of available data' : 'View previous period data'}}"></i>
				</button>
				<div style="font-weight: bold;" *ngIf="viewPeriod">
					<span style="font-size: 18px">{{viewPeriod.startDate| date : periodDateFormat: uiTimeZone}}</span>
					<span style="font-size: 18px" *ngIf="summaryView === 'Weekly'"> -
						{{viewPeriod.endDate| date : periodDateFormat: uiTimeZone}}</span>
					<mat-form-field [formGroup]="dateForm" class="date-picker-custom" *ngIf="summaryView !== 'Live'">
						<input matInput type="text" [matDatepicker]="picker" (dateChange)="viewPeriodChangeHandler()"
							formControlName="dateSelect" [min]="MIN_DATE" [max]="MAX_DATE" style="display: none;">
						<mat-datepicker-toggle matSuffix [for]="picker" class="btn-date-picker"></mat-datepicker-toggle>
						<mat-datepicker touchUi="{{smallScreen}}" #picker
							startView="{{summaryView === 'Yearly'? 'multi-year' : (summaryView === 'Monthly'?'year':'month')}}"
							[startAt]="viewPeriod.startDate" (yearSelected)="viewPeriodChangeHandler($event, picker)"
							(monthSelected)="viewPeriodChangeHandler($event, picker)"></mat-datepicker>
					</mat-form-field>
				</div>

				<button mat-mini-fab class="blue-button rounded-button ml-2" (click)="viewNextPeriod()"
					[disabled]="disableNextPeriod">
					<i class="ace-icon fa fa-chevron-right ace-icon-align"
						matTooltip="{{disableNextPeriod? 'You are viewing the last period of available data' : 'View next period data'}}"></i>
				</button>
			</div>
		</div>
		<div fxFlex="50" fxLayout="row" class="filter-view mb-3">
			<div class="text-nowrap">Filter by:</div>
			<mat-tab-group class="tab-menu-group toggle-group" (selectedTabChange)="changeSummaryView($event)"
				[selectedIndex]="currentViewIndex">
				<mat-tab *ngFor="let view of summaryViews;let index = index; let last = last;"
					[ngStyle.lt-sm]="{'min-width.px':'85'}">
					<ng-template mat-tab-label>
						<!-- <mat-icon *ngIf="view==='Live'"
              [ngClass]="liveStreamOn?(liveStreamOn==='Y'?'text-success':'text-danger'):'text-secondary'">
              fiber_manual_record
            </mat-icon> -->
						<span mat-icon-button
							[matTooltip]="view==='Live'?('Last updated on ' + (lastUpdatedOn | date :'medium')):''"
							style="font-weight: bold;">{{view}}</span>
					</ng-template>
				</mat-tab>
			</mat-tab-group>
		</div>
	</div>

	<div *ngIf="summaryView === 'Live'" fxLayout="row" fxFlex="100" fxLayoutAlign="end">
		<button type="button" class="btn btn-link ml-2" (click)="refreshEvent()">Refresh </button>
	</div>


	<div *ngIf="summaryView === 'Live'" fxFlex="100" fxLayout="row wrap" class="tile-card">
		<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

			<!-- Index Column -->
			<ng-container matColumnDef="index" sticky>
				<th mat-header-cell *matHeaderCellDef class="fontheader"> Index </th>
				<td mat-cell *matCellDef="let element; let i = index"> {{i + 1}} </td>
			</ng-container>

			<!-- Account Id Column -->
			<ng-container matColumnDef="accountId">
				<th mat-header-cell *matHeaderCellDef mat-sort-header class="fontheader"> Account Id </th>
				<td mat-cell *matCellDef="let element"> <button type="button" class="btn btn-link"
						(click)="navigateToDashboard(element)">{{element.accountId}} </button></td>
			</ng-container>

			<ng-container matColumnDef="generation">
				<th mat-header-cell *matHeaderCellDef class="fontheader"> Charged (kWh)</th>
				<td mat-cell *matCellDef="let element"> <label mat-label
						*ngIf="element.energyProfile.generationProfile.BATTERY !== undefined && element.energyProfile.generationProfile.BATTERY.profile !== undefined">
						{{ element.energyProfile.generationProfile.BATTERY.profile.generation |  number : '1.0-2'}}
					</label> </td>
				<!-- <td mat-cell *matCellDef="let element">  </td> -->
			</ng-container>

			<ng-container matColumnDef="netZero">
				<th mat-header-cell *matHeaderCellDef class="fontheader"> Discharged </th>
				<td mat-cell *matCellDef="let element"><label mat-label
						*ngIf="element.energyProfile.generationProfile.BATTERY !== undefined && element.energyProfile.generationProfile.BATTERY.profile !== undefined">
						{{element.energyProfile.generationProfile.BATTERY.profile.netZero.hours |  number : '1.0-2' }} </label> </td>
			</ng-container>

			<!-- Solar Column -->
			<ng-container matColumnDef="solar">
				<th mat-header-cell *matHeaderCellDef class="fontheader"> Solar (kW) </th>
				<td mat-cell *matCellDef="let element"> <label mat-label
						*ngIf="element.energyProfile.generationProfile.BATTERY !== undefined && element.energyProfile.generationProfile.BATTERY.live !== undefined">
						{{ element.energyProfile.generationProfile.BATTERY.live.generation |  number : '1.0-2'}}
					</label> </td>

			</ng-container>

			<!-- Grid Column -->
			<ng-container matColumnDef="grid">
				<th mat-header-cell *matHeaderCellDef class="fontheader"> Grid (kW) </th>
				<td mat-cell *matCellDef="let element"> <label mat-label
						*ngIf="element.energyProfile.generationProfile.BATTERY !== undefined && element.energyProfile.generationProfile.BATTERY.live !== undefined">{{(element.energyProfile.live.consumption - element.energyProfile.generationProfile.BATTERY.live.generation) | number : '1.0-2'}}
					</label></td>
			</ng-container>

			<!-- Home Usasge Column -->
			<ng-container matColumnDef="homeUsage">
				<th mat-header-cell *matHeaderCellDef class="fontheader"> Battery Charge (kW) </th>
				<td mat-cell *matCellDef="let element"> <label mat-label
						*ngIf="element.energyProfile !== undefined && element.energyProfile.live !== undefined">
						{{ element.energyProfile.live.consumption |  number : '1.0-2'}} </label> </td>
			</ng-container>

			<!-- Current Performance Column -->
			<ng-container matColumnDef="currentPerformance">
				<th mat-header-cell *matHeaderCellDef class="fontheader"> Home Usage (kW)</th>
				<td mat-cell *matCellDef="let element"> <label mat-label
						*ngIf="element.energyProfile.generationProfile.BATTERY !== undefined && element.energyProfile.generationProfile.BATTERY.live !== undefined">{{ (element.energyProfile.generationProfile.BATTERY.live.performance * 100) | number : '1.0-2'}}</label>
				</td>
			</ng-container>

			<!-- Gross Generation Column -->
			<ng-container matColumnDef="grossGeneration">
				<th mat-header-cell *matHeaderCellDef class="fontheader"> Grid (kW) </th>
				<td mat-cell *matCellDef="let element"> <label mat-label
						*ngIf="element.energyProfile.generationProfile.BATTERY !== undefined && element.energyProfile.generationProfile.BATTERY.live !== undefined">
						{{ element.energyProfile.generationProfile.BATTERY.live.generation |  number : '1.0-2'}}
					</label> </td>
			</ng-container>

			<!-- Expected Generation Column -->
			<ng-container matColumnDef="expectedGeneration">
				<th mat-header-cell *matHeaderCellDef class="fontheader"> Battery Discharge (kW) </th>
				<td mat-cell *matCellDef="let element"> <label mat-label
						*ngIf="element.energyProfile.generationProfile.BATTERY !== undefined && element.energyProfile.generationProfile.BATTERY.live !== undefined">{{ element.energyProfile.generationProfile.BATTERY.live.forecastCapacity | number : '1.0-2'}}</label>
				</td>
			</ng-container>

			<!-- Consumption Cost Column -->
			<ng-container matColumnDef="consumptionCost">
				<th mat-header-cell *matHeaderCellDef class="fontheader"> Site Energy Cost </th>
				<td mat-cell *matCellDef="let element"> <label mat-label
						*ngIf="element.energyProfile !== undefined && element.energyProfile.live !== undefined">
						{{element.energyProfile.live.energyCost | currency:'CAD':'symbol-narrow':'1.0-2'}} </label>
				</td>
			</ng-container>

			<!-- Solar Savings Column -->
			<ng-container matColumnDef="solarSavings">
				<th mat-header-cell *matHeaderCellDef class="fontheader"> Battery Savings </th>
				<td mat-cell *matCellDef="let element"> <label mat-label
						*ngIf="element.energyProfile.generationProfile.BATTERY !== undefined && element.energyProfile.generationProfile.BATTERY.live !== undefined">
						{{ element.energyProfile.generationProfile.BATTERY.live.generationSavings |  currency:'CAD':'symbol-narrow':'1.0-2'}}
					</label> </td>
			</ng-container>

			<!-- Total Energy Cost Column -->
			<ng-container matColumnDef="totalEnergyCost">
				<th mat-header-cell *matHeaderCellDef class="fontheader"> Net Cost </th>
				<td mat-cell *matCellDef="let element"><label mat-label
						*ngIf="element.energyProfile.generationProfile.BATTERY !== undefined && element.energyProfile.generationProfile.BATTERY.live !== undefined">
						{{(element.energyProfile.live.energyCost  - element.energyProfile.generationProfile.BATTERY.live.generationSavings) | currency:'CAD':'symbol-narrow':'1.0-2'}}
					</label> </td>
			</ng-container>

			<ng-container matColumnDef="source">
				<th mat-header-cell *matHeaderCellDef class="fontheader"> Source </th>
				<td mat-cell *matCellDef="let element">
					<li style="display: flex;"
						*ngFor="let contributor of element.energyProfile.contributors; let i = index">
						{{ contributor }}
					</li>
				</td>
			</ng-container>

			<!-- Updated  Column -->
			<ng-container matColumnDef="updated">
				<th mat-header-cell *matHeaderCellDef class="fontheader" mat-sort-header> Updated </th>
				<td mat-cell *matCellDef="let element" [ngClass]="{negative : compareDate(TODAY_DATE, element.updated) }"> {{element.updated | date: 'MMM d, y, HH:mm'}} </td>
			</ng-container>


			<!-- Header row first group -->
			<ng-container matColumnDef="header-row-first-group">
				<th mat-header-cell *matHeaderCellDef [style.text-align]="center" [attr.colspan]="2"></th>
			</ng-container>

			<!-- Header row second group -->

			<ng-container matColumnDef="header-row-second-group">
				<th mat-header-cell *matHeaderCellDef [attr.colspan]="2" class="fontheader">  </th>
			</ng-container>
			<ng-container matColumnDef="header-row-third-group">
				<th mat-header-cell *matHeaderCellDef [attr.colspan]="3" class="fontheader"> Charge </th>
			</ng-container>

			<ng-container matColumnDef="header-row-fourth-group">
				<th mat-header-cell *matHeaderCellDef [attr.colspan]="3" class="fontheader"> Discharge </th>
			</ng-container>

			<ng-container matColumnDef="header-row-fifth-group">
				<th mat-header-cell *matHeaderCellDef [attr.colspan]="3" class="fontheader"> Cost </th>
			</ng-container>

			<ng-container matColumnDef="header-row-sixth-group">
				<th mat-header-cell *matHeaderCellDef [attr.colspan]="2"> </th>
			</ng-container>


			<tr mat-header-row
				*matHeaderRowDef="['header-row-first-group', 'header-row-second-group', 'header-row-third-group', 'header-row-fourth-group', 'header-row-fifth-group', 'header-row-sixth-group']">
			</tr>
			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
			
		</table>

		<div fxFlex="100" *ngIf="!userLiveMessage" class="mt-2" fxFlexAlign="end center">
			<mat-paginator [pageSizeOptions]="[50, 100, 150, 200]" showFirstLastButtons fxLayoutAlign="end">
			</mat-paginator>
		</div>

		<div fxFlex="100">
			<table fxFlex="100" *ngIf="userLiveMessage || (dataSource && !dataSource.data.length)">
			  <tr>
				<td style="text-align: center;" [attr.colspan]="displayedColumns.length">
				  {{userLiveMessage}}
				</td>
			  </tr>
			</table>
		  </div>

	</div>



	<div *ngIf="summaryView === 'Daily' || summaryView === 'Weekly' || summaryView === 'Monthly' || summaryView === 'Yearly'"
		fxFlex="100" fxLayout="row wrap" class="tile-card">
		<table mat-table [dataSource]="dataViewSource" matSort class="mat-elevation-z8">

			<!-- Index Column -->
			<ng-container matColumnDef="index" sticky>
				<th mat-header-cell *matHeaderCellDef class="fontheader"> Index </th>
				<td mat-cell *matCellDef="let element; let i = index"> {{i + 1}} </td>
			</ng-container>

			<!-- Account Id Column -->
			<ng-container matColumnDef="accountId">
				<th mat-header-cell *matHeaderCellDef mat-sort-header class="fontheader"> Account Id </th>
				<td mat-cell *matCellDef="let element"> <button type="button" class="btn btn-link"
						(click)="navigateToDashboard(element)">{{element.accountId}} </button></td>
			</ng-container>

			<ng-container matColumnDef="generation">
				<th mat-header-cell *matHeaderCellDef class="fontheader"> Charged (kWh) </th>
				<td mat-cell *matCellDef="let element"> <label mat-label>
						{{ element.energyMix.consumption |  number : '1.0-2'}} </label> </td>
				<!-- <td mat-cell *matCellDef="let element">  </td> -->
			</ng-container>

			<ng-container matColumnDef="netZero">
				<th mat-header-cell *matHeaderCellDef class="fontheader"> Discharged (kWh)</th>
				<td mat-cell *matCellDef="let element"><label mat-label> {{ element.energyMix.generation |  number : '1.0-2'}}  </label> </td>
			</ng-container>

			<!-- Solar Column -->
			<ng-container matColumnDef="solar">
				<th mat-header-cell *matHeaderCellDef class="fontheader"> Solar (kWh) </th>
				<td mat-cell *matCellDef="let element"> <label mat-label>
						{{ element.solar |  number : '1.0-2'}} </label> </td>

			</ng-container>

			<!-- Grid Column -->
			<ng-container matColumnDef="grid">
				<th mat-header-cell *matHeaderCellDef class="fontheader"> Grid (kWh) </th>
				<!--<td mat-cell *matCellDef="let element"> <label mat-label  > {{ (element.consumption - element.energyMix.generation) | number : '1.2-2'}} </label></td>-->
				<td mat-cell *matCellDef="let element"> <label mat-label> {{ element.gridCharge | number : '1.0-2'}}
					</label></td>
				<!-- <td mat-cell *matCellDef="let element"> <label mat-label  > {{ element.consumption  | number : '1.2-2'}} </label></td> -->
			</ng-container>

			<!-- Home Usasge Column -->
			<ng-container matColumnDef="homeUsage">
				<th mat-header-cell *matHeaderCellDef class="fontheader"> Battery Charge (kWh) </th>
				<td mat-cell *matCellDef="let element"> {{element.chargingData | number : '1.0-2'}} <label mat-label> 
					</label> </td>
			</ng-container>

			<!-- Current Performance Column -->
			<ng-container matColumnDef="currentPerformance">
				<th mat-header-cell *matHeaderCellDef class="fontheader"> Home Usage (kWh) </th>
				<td mat-cell *matCellDef="let element"> <label
						mat-label>{{ (element.homeUsage) | number : '1.0-2'}}</label> </td>
			</ng-container>

			<!-- Gross Generation Column -->
			<ng-container matColumnDef="grossGeneration">
				<th mat-header-cell *matHeaderCellDef class="fontheader">Grid (kWh) </th>
				<td mat-cell *matCellDef="let element"> {{ (element.gridDischarge) | number : '1.0-2'}} <label mat-label> 
					</label> </td>
			</ng-container>

			<!-- Expected Generation Column -->
			<ng-container matColumnDef="expectedGeneration">
				<th mat-header-cell *matHeaderCellDef class="fontheader"> Battery Discharge (kWh) </th>
				<td mat-cell *matCellDef="let element"> {{ (element.dischargingData) | number : '1.0-2'}}<label
						mat-label></label> </td>
			</ng-container>

			<!-- Consumption Cost Column -->
			<ng-container matColumnDef="consumptionCost">
				<th mat-header-cell *matHeaderCellDef class="fontheader"> Site Energy Cost </th>
				<td mat-cell *matCellDef="let element"> <label mat-label>
						{{element.energyCost | currency:'CAD':'symbol-narrow':'1.0-2'}} </label> </td>
			</ng-container>

			<!-- Solar Savings Column -->
			<ng-container matColumnDef="solarSavings">
				<th mat-header-cell *matHeaderCellDef class="fontheader"> Battery Savings </th>
				<td mat-cell *matCellDef="let element"> <label mat-label>
						{{ element.energyMix.generationSavings |  currency:'CAD':'symbol-narrow':'1.0-2'}} </label> </td>
			</ng-container>

			<!-- Total Energy Cost Column -->
			<ng-container matColumnDef="totalEnergyCost">
				<th mat-header-cell *matHeaderCellDef class="fontheader"> Net Cost </th>
				<td mat-cell *matCellDef="let element" [ngClass]="{'negative' : element.netEnergyCost < 0 }">
						{{(element.netEnergyCost) | currency:'CAD':'symbol-narrow':'1.0-2'}}
					 </td>
			</ng-container>

			<ng-container matColumnDef="source">
				<th mat-header-cell *matHeaderCellDef class="fontheader"> Source </th>
				<td mat-cell *matCellDef="let element">
					<!-- <li style="display: flex;" *ngFor="let contributor of element.energyProfile.contributors; let i = index"> 
     					{{ contributor }}
					</li>  -->
				</td>
			</ng-container>

			<!-- Updated  Column -->
			<ng-container matColumnDef="updated">
				<th mat-header-cell *matHeaderCellDef class="fontheader" mat-sort-header> Updated </th>
				<td mat-cell *matCellDef="let element"> {{element.updated | date: 'MMM d, y, HH:mm'}} </td>
			</ng-container>


			<!-- Header row first group -->
			<ng-container matColumnDef="header-row-first-group">
				<th mat-header-cell *matHeaderCellDef [style.text-align]="center" [attr.colspan]="2"></th>
			</ng-container>

			<!-- Header row second group -->

			<ng-container matColumnDef="header-row-second-group">
				<th mat-header-cell *matHeaderCellDef [attr.colspan]="2" class="fontheader">  </th>
			</ng-container>
			<ng-container matColumnDef="header-row-third-group">
				<th mat-header-cell *matHeaderCellDef [attr.colspan]="3" class="fontheader"> Charge </th>
			</ng-container>

			<ng-container matColumnDef="header-row-fourth-group">
				<th mat-header-cell *matHeaderCellDef [attr.colspan]="3" class="fontheader"> Discharhge </th>
			</ng-container>

			<ng-container matColumnDef="header-row-fifth-group">
				<th mat-header-cell *matHeaderCellDef [attr.colspan]="3" class="fontheader"> Cost </th>
			</ng-container>

			<ng-container matColumnDef="header-row-sixth-group">
				<th mat-header-cell *matHeaderCellDef [attr.colspan]="2"> </th>
			</ng-container>


			<tr mat-header-row
				*matHeaderRowDef="['header-row-first-group', 'header-row-second-group', 'header-row-third-group', 'header-row-fourth-group', 'header-row-fifth-group', 'header-row-sixth-group']">
			</tr>
			<tr mat-header-row *matHeaderRowDef="displayedViewColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedViewColumns;"></tr>
			
		</table>

		<div fxFlex="100" *ngIf="!userMessageOthers" class="mt-2" fxFlexAlign="end center">
			<mat-paginator [pageSizeOptions]="[50, 100, 150, 200]" showFirstLastButtons fxLayoutAlign="end">
			</mat-paginator>
		</div>
		<div fxFlex="100">
			<table fxFlex="100" *ngIf="userMessageOthers || (dataViewSource && !dataViewSource.data.length)">
				<tr>
					<td style="text-align: center;" [attr.colspan]="displayedColumns.length">
						{{userMessageOthers}}
					</td>
				</tr>
			</table>
		</div>

	</div>
</div>
