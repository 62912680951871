import { Component, OnInit, Input } from '@angular/core';
import { UserSessionService } from 'src/app/framework/shared/service/user-session.service';
import { MenuItem } from 'src/app/framework/shared/model/menuitems';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'underscore';
import { IUser } from 'src/app/framework/shared/model/iuser';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  loggedUser: IUser;

  sideMenuList: MenuItem[];
  custData = [];
  accountId: string;

  @Input() showSideMenu = false;
  @Input() menuItems: MenuItem[];

  selectedMenu: MenuItem;
  hasAdminRole = false;
  homePageUrl: any;
  ldcList = ['LH', 'ENMAX'];
  LDC: string;

  constructor(private userSession: UserSessionService, private router: Router, private cookieService: CookieService) { }

  ngOnInit(): void {
    this.userSession.loggedUser.subscribe(user => {
      if (user && user !== this.loggedUser) {
        this.loggedUser = user;
        const userRoles = this.loggedUser.roles;
        if (userRoles && !_.isEmpty(userRoles)) {
          this.hasAdminRole = _.contains(userRoles, 'ADMIN');
        }
        if (this.loggedUser.customFields && this.loggedUser.customFields !== null && this.loggedUser.customFields.LDC) {
          this.LDC = this.loggedUser.customFields.LDC;
        }
      }
    });
  }

  onLogoClick(): void {
    const appHomeUrl = window.top.location.origin;
    this.homePageUrl = appHomeUrl + '/home';
  }

  changeLDC(ldcId: string): void {
    this.LDC = ldcId;
    this.loggedUser.customFields = this.loggedUser.customFields || {};
    this.loggedUser.customFields.LDC = this.LDC;
    this.userSession.setLoggedUser(this.loggedUser);
    if (this.router.url.indexOf('/admin') < 0) {
      this.router.navigate(['/admin']);
    }
  }

  /* viewAccounList(): void {
    this.loggedUser.customFields = this.loggedUser.customFields || {};
    this.loggedUser.customFields.accountId = null;
    this.userSession.setLoggedUser(this.loggedUser);
    this.router.navigate(['/admin']);
  } */

  actionLogout(): void {
    this.userSession.invalidateAccessToken().subscribe(res => {
      if (res && res.IsPosted === true) {
        console.log('Successfully logged out:', res);
      } else {
        console.log('Error while logging out:', res);
      }
      this.userSession.setLoggedUser(null);
      this.cookieService.delete('lastRoute', '/');
      this.cookieService.delete(environment.userToken, '/');
      this.router.navigate(['/home']);
    });
  }
}
