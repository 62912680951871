<div fxFlex="100" fxLayout="row" fxLayoutAlign="center" class="footer-row py-1">
  <div fxLayout="row" fxLayoutAlign="space-between center" class="page-footer px-3">
    <div fxLayout="row" fxLayoutAlign="start center">
      <div class="icon-lh mx-3"></div>London
      Hydro, 111 Horton Street, P.O. Box 2700, London, ON N6A 4H6 <br>
      Phone: (519) 661-5503, Fax: (519) 661-5838
    </div>
    <div fxLayout="row" fxLayoutAlign="start center">Powered By <div class="icon-cmd mx-1"></div><span> Green Button</span></div>
    <div fxLayout="row" fxLayout.lt-md="column">
      <div class="footer-link border-dark" [ngClass.gt-md]="'border-right px-2'" [ngClass.md]="'border-right'"
        [ngClass.lt-md]="'border-bottom'">
        <a href="https://www.londonhydro.com/privacy">Privacy Policy</a></div>
      <div class="footer-link" [ngClass.gt-md]="'px-2'"><a
          href="https://www.londonhydro.com/about-us/about-london-hydro/accessibility">Accessibility
          Plan</a></div>
    </div>
  </div>

</div>
<div *ngIf="showCookieInfo" fxFlex="100" fxLayout="row" fxLayoutAlign="center center" class="py-2 bg-dark text-white fixed-bottom"
  style="opacity: 0.8;">
  <p class="mb-0 ml-2 cookie-consent">We use cookies to give you the best experience. Read our
    <a href="https://www.londonhydro.com/privacy" target="_new" class="privacy-link"><u>privacy
      policy</u></a>
    <button type="button" class="btn btn-light ml-2 p-0 px-1" (click)="hideCookieInfo()">X</button></p>
</div>