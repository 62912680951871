import { NgModule, InjectionToken } from '@angular/core';
import { Routes, RouterModule, ActivatedRouteSnapshot } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { PageNotFoundComponent } from './modules/layout/components/page-not-found/page-not-found.component';
import { RouteGuard } from './framework/shared/guards/route-guard.service';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { OurSolutionsComponent } from './components/our-solutions/our-solutions.component';
import { OpenDataComponent } from './components/open-data/open-data.component';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { DemoComponent } from './components/demo/demo.component';
const externalUrlProvider = new InjectionToken('externalUrlRedirectResolver');

const ROUTES: Routes = [
  /* { path: 'home', component: LandingHomePageComponent}, */
  {
    path: '', component: HomeComponent,
    children: [
      {path: 'demo', component: DemoComponent},
      {      
        path: 'home', component: LandingPageComponent,
        children: [
          {
            path: 'aboutus',
            component: AboutUsComponent
          },
          {
            path: 'oursolutions',
            component: OurSolutionsComponent
          },
          {
            path: 'opendata',
            component: OpenDataComponent
          },
          {
            path: 'login',
            component: LoginPageComponent
          }
        ]
      },
      {
        path: 'login',
        loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
      },
      {
        path: 'der',
        canActivate: [RouteGuard],
        loadChildren: () => import('./modules/der/der.module').then(m => m.DerModule)
      },
      {
        path: 'asset',
        canActivate: [RouteGuard],
        loadChildren: () => import('./modules/asset/asset.module').then(m => m.AssetModule)
      },
      {
        path: 'myebill',
        canActivate: [RouteGuard],
        loadChildren: () => import('./modules/ebill/ebill.module').then(m => m.EbillModule)
      },
      {
        path: 'greenbutton',
        canActivate: [RouteGuard],
        loadChildren: () => import('./modules/greenbutton/greenbutton.module').then(m => m.GreenButtonModule)
      },
      {
        path: 'developerplace',
        canActivate: [RouteGuard],
        loadChildren: () => import('./modules/developerplace/developerplace.module').then(m => m.DeveloperPlaceModule)
      },
      {
        path: 'admin',
        canActivate: [RouteGuard],
        loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule)
      },
      {
        path: 'secondasset',
        canActivate: [RouteGuard],
        loadChildren: () => import('./modules/secondasset/secondasset.module').then(m => m.SecondassetModule)
      },
      {
        path: 'delegate',
        canActivate: [RouteGuard],
        loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule)
      },
      {
        path: 'profile',
        canActivate: [RouteGuard],
        loadChildren: () => import('./modules/profile/profile.module').then(m => m.ProfileModule)
      },
      {
        path: 'digitalwallet',
        canActivate: [RouteGuard],
        loadChildren: () => import('./modules/digitalwallet/digitalwallet.module').then(m => m.DigitalwalletModule)
      },
      {
        path: 'widget',
        canActivate: [RouteGuard],
        loadChildren: () => import('./modules/widget-playground/widget-playground.module').then(m => m.WidgetPlaygroundModule)
      },
      { path: 'external', component: HomeComponent, canActivate: [externalUrlProvider] },
      {
        path: '**',
        redirectTo: 'home'
      }
    ]
  },
  { path: 'external', component: HomeComponent, canActivate: [externalUrlProvider] },
  { path: '**', component: PageNotFoundComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(ROUTES, { useHash: false, enableTracing: false })],
  exports: [RouterModule],
  providers: [{
    provide: externalUrlProvider,
    useValue: (route: ActivatedRouteSnapshot) => {
      const externalUrl = route.paramMap.get('externalUrl');
      const newtab = route.paramMap.get('newtab');
      if (newtab) {
        window.open(externalUrl, newtab);
      } else {
        window.open(externalUrl, '_self');
      }
    },
  }],
})
export class AppRoutingModule { }
