import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { CustomerService } from '../service/customer.service';
import { UserSessionService } from '../service/user-session.service';
import * as _ from 'underscore';
import { User } from 'src/app/modules/auth/model/user';

@Injectable({
  providedIn: 'root'
})
export class RouteGuard implements CanActivate, CanActivateChild {

  constructor(private router: Router, private cookieService: CookieService, private userSession: UserSessionService,
              private customerService: CustomerService, private route: ActivatedRoute) { }


  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.validateUserToken(state);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.validateUserToken(state);
  }

  private validateUserToken(state: RouterStateSnapshot): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const userTokenValue: string = this.cookieService.get(environment.userToken);
      // console.log(state.url + ' usertoken::::' + userTokenValue, window.location.host);
      if (userTokenValue != null && userTokenValue !== '') {
        // logged in so return true
        if (!this.userSession.userDataLoaded) {
          this.userSession.getUserSession().toPromise().then(
            user => { // Success
              console.log('::RouteGaurd::fetched:::loggedUser::', user);
              if (user) {
                const loggedUser = _.extend(new User(), user);
                loggedUser.customFields = loggedUser.customFields || {};
                loggedUser.customFields.LDC = loggedUser.customFields.LDC || 'LH';
                const accountId = loggedUser.customFields.accountId;
                if (accountId && accountId !== null && accountId.trim().length > 0) {
                  this.customerService.getCustomerAssetProfileAsync(accountId);
                }
                this.userSession.setLoggedUser(loggedUser);
                resolve(true);
              } else {
                console.log('::RouteGaurd::no user found:::redirecting to login:::');
                this.redirctToLogin();
                resolve(false);
              }
            },
            msg => { // Error
              console.log('::RouteGaurd::no user found:::redirecting to login:::', msg);
              this.redirctToLogin();
              reject(msg);
            }
          );
        } else {
          // console.log('::RouteGaurd::loggedUser present::');
          resolve(true);
        }
      } else {
        console.log('::RouteGaurd::no token found:::redirecting to login:::');
        this.redirctToLogin();
        resolve(false);
      }
    });
  }

  private redirctToLogin(): void {
    // this.router.navigate(['/login']);
    console.log('redirctToLogin::::::::::::' + this.router.url);
    const appHomeUrl = window.top.location.origin;
    console.log('appHomeUrl::', appHomeUrl);
    const homeUrl = appHomeUrl + '?action=login&return_url=' + this.router.url;
    this.router.navigate(['/external', { externalUrl: homeUrl }]);
  }
}
