import { Component, OnInit } from '@angular/core';
import { PortalService } from 'src/app/service/portal.service';

@Component({
  selector: 'app-demo',
  templateUrl: './demo.component.html',
  styleUrls: ['./demo.component.scss']
})
export class DemoComponent implements OnInit {

  ldcAssetsSummary: Array<any>;

  constructor(private portalService: PortalService) { }

  ngOnInit(): void {
    this.loadLdcAssets();
  }

  loadLdcAssets(): void {
    this.portalService.getLdcAssets().subscribe(assetsSummary => {
      if (assetsSummary) {
        this.ldcAssetsSummary = assetsSummary;
      }
    });
  }

}
