import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { MenuService } from 'src/app/framework/shared/service/menu.service';
import { MenuItem } from 'src/app/framework/shared/model/menuitems';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss']
})
export class PageTitleComponent implements OnInit {

  selectedMenu: MenuItem;
  toggleOn = false;

  @Input() showMenuOption = false;
  @Output() toogleMenu = new EventEmitter<boolean>();

  constructor(private menuService: MenuService) { }

  ngOnInit(): void {
    this.menuService.selectedMenu.subscribe(sMenu => {
      if (sMenu) {
        this.selectedMenu = sMenu;
      }
    });
  }

  toggleSideMenu(): void {
    this.toggleOn = !this.toggleOn;
    this.toogleMenu.emit(this.toggleOn);
  }
}
