import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as _ from 'underscore';
import { MenuItem } from '../model/menuitems';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  private lappMenuList: BehaviorSubject<MenuItem[]> = new BehaviorSubject<MenuItem[]>(null);
  appMenuList = this.lappMenuList.asObservable();

  private lselectedMenu: BehaviorSubject<MenuItem> = new BehaviorSubject<MenuItem>(null);
  selectedMenu = this.lselectedMenu.asObservable();
  previousMenu: MenuItem = null;

  constructor() { }

  changeActiveMenu(sMenu: MenuItem): void {
    if (sMenu && (!this.previousMenu || (this.previousMenu && sMenu.id !== this.previousMenu.id))) {
      console.log('changeActiveMenu:::', sMenu);
      this.previousMenu = sMenu;
      this.lselectedMenu.next(sMenu);
    }
  }

  loadApplicationMenu(activeMenu: string = null): void {
    const menuList = new Array<MenuItem>();
    menuList.push(MenuItem.create(1, 'der_visibility', 'DER Visibility', '/der', null, 'icon-menu-der', 'DER Visibility'));
    menuList.push(MenuItem.create(2, 'my_assets', 'My Assets', '/asset', null, 'icon-menu-asset', 'My DER Assets'));
    menuList.push(MenuItem.create(100, 'second_asset', 'Assets', '/secondasset', null, 'icon-menu-asset', 'All DER Assets', null, 'ADMIN'));
    menuList.push(MenuItem.create(3, 'my_profile', 'Profiles', '/profile', null, 'icon-menu-admin', 'Profiles', null, 'ADMIN'));
    menuList.push(MenuItem.create(4, 'digital_wallet', 'Digital Wallet', '/digitalwallet', null, 'icon-menu-wallet', 'Digital Wallet (Development in Progress)', null, 'DEVELOPER'));
    menuList.push(MenuItem.create(5, 'my_greenbutton', 'Green Button', '/greenbutton', null, 'icon-menu-gb', 'Green Button'));
    // menuList.push(MenuItem.create(6, 'my_ebill', 'My Ebill', '/myebill', null, 'icon-menu-wallet', 'My Ebill'));
    menuList.push(MenuItem.create(70, 'delegate', 'Account Home', '/delegate/home', null, 'icon-menu-home', 'Accounts Home', null, 'DELEGATE'));
    menuList.push(MenuItem.create(80, 'admin', 'Admin', '/admin', null, 'icon-menu-admin', 'Admin', null, 'ADMIN'));
    menuList.push(MenuItem.create(99, 'widget_lib', 'Widget Library', '/widget', null, 'icon-menu-devops', 'Widget PlayGround', null, 'DEVELOPER'));
    // menuList.push(MenuItem.create(100, 'developer_place', 'Developer Place', '/developerplace', null, 'icon-menu-dev', 'Demo', null, 'DEVELOPER'));

    this.lappMenuList.next(menuList);
    console.log('Menu loaded');
    if (activeMenu) {
      const sMenu = _.find(menuList, (menu: MenuItem) => {
        return activeMenu.startsWith(menu.action);
      });
      console.log('Menu loaded::activeMenu:: ' + activeMenu, sMenu);
      if (sMenu) {
        this.changeActiveMenu(sMenu);
      }
    }
  }
}
