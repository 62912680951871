import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { of, Observable, Subject } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  private assetProfileCache: Map<string, any>;

  constructor(private http: HttpClient) {
    this.resetCache();
  }

  resetCache(): void {
    this.assetProfileCache = new Map();
  }

  getCustomerAssetProfile(accountId: string): Observable<any> {
    if (this.assetProfileCache && this.assetProfileCache.has(accountId)) {
      return of(this.assetProfileCache.get(accountId));
    } else {
      const custAddrDataUrl = `/account/${accountId}/asset/profile`;
      console.log(environment.derMsEndPoint + custAddrDataUrl);
      return this.http.get<any>(environment.derMsEndPoint + custAddrDataUrl)
        .pipe(
          tap(res => {
            if (res) {
              this.assetProfileCache.set(accountId, res);
            }
            console.log('fetched Customer profiles:', res);
          }),
          catchError(this.handleError('getCustomerAssetProfile:', null))
        );
    }
  }

  async getCustomerAssetProfileAsync(accountId: string): Promise<any> {
    console.log('Starting Fetching async............');
    const custAddrDataUrl = `/account/${accountId}/asset/profile`;
    console.log(environment.derMsEndPoint + custAddrDataUrl);
    const res = await this.http.get<any>(environment.derMsEndPoint + custAddrDataUrl).toPromise();
    this.assetProfileCache.set(accountId, res);
    console.log('fetched Customer profiles async............', res);
    return res;
  }


  private handleError<T>(operation = 'operation', result?: T): any {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      // TODO: better job of transforming error for user consumption
      console.error(`${operation} failed: ${error.message}`);
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
