import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { SolarAssetService } from '../../service/solarasset.service';
import * as _ from 'underscore';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { LocationStrategy } from '@angular/common';
import { message } from 'src/app/framework/ui-lib/messages/message';

@Component({
  selector: 'app-thrmostatasset',
  templateUrl: './thrmostatasset.component.html',
  styleUrls: ['./thrmostatasset.component.scss']
})
export class ThrmostatassetComponent implements OnInit {
  dataLoadPending = false;
  masterAssetList: Array<any>;
  userMessage: string;
  userLiveMessage: string;
  errorState = 'info';
  subscription: Subscription;
  dataSource: MatTableDataSource<any>;

  chargingData: number;
  dischargingData: number;
  solar: number;
  gridCharge: number;
  gridDischarge: number;
  homeUsage: number;
  displayedColumns: string[] = ['index', 'accountId', 'device', 'comfortPreference', 'duration', 'outdoorTemperature', 'indoorTemperature', 'hvacMode', 'coolSetpoint', 'heatSetpoint', 'status', 'updated'];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(private solarAssetsService: SolarAssetService, private locationStrategy: LocationStrategy) { 
      history.pushState(null, null, location.href);
      this.locationStrategy.onPopState(() => {
      history.pushState(null, null, location.href);
    }) }

  ngOnInit(): void {
    this.getThemostatyAssetLiveList();
  }

  refreshEvent(): void {
    this.getThemostatyAssetLiveList();
  }

  private getThemostatyAssetLiveList(): void {
    this.dataLoadPending = true;
    this.masterAssetList = [];

    this.solarAssetsService.getSolarAssetList().subscribe(jsonData => {

      this.masterAssetList = jsonData;
      const cthis = this;

      console.log('Display themostat asset data: ', this.masterAssetList);

      if (this.masterAssetList && !_.isEmpty(this.masterAssetList)) {
        this.userLiveMessage = null;
        this.errorState = 'info';
        const lthis = this;

        const filteredList = _.filter(this.masterAssetList, function (ap) {
          if (ap.thermostatProfile  !== undefined){
            return (ap.thermostatProfile  !== undefined );
          }

        });
        console.log('masterAssetList List:::', filteredList);

        this.dataSource = new MatTableDataSource(filteredList);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataLoadPending = false;
      } else {
        this.userLiveMessage = message.noDataFound;
        this.errorState = 'warning';
      }
      this.dataLoadPending = false;

    });
  }

}
