import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {

  iamUrl: string;

  constructor() {
  }

  ngOnInit(): void {
    const appHomeUrl = window.top.location.origin;
    this.iamUrl = environment.loginUrl + '?action=login&return_url=' + (appHomeUrl + '/login');
  }

}
