import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  private assetProfileCache: Map<string, any>;

  constructor(private http: HttpClient) {
    this.resetCache();
  }

  resetCache(): void {
    this.assetProfileCache = new Map();
  }

  getAssetProfiles(ldcVal: string): Observable<any> {
    if (this.assetProfileCache && this.assetProfileCache.has(ldcVal)) {
      return of(this.assetProfileCache.get(ldcVal));
    } else {
      const assetProfileApiUrl = `/asset/profile?ldcId=${ldcVal}`;
      return this.http.get<any>(environment.derMsEndPoint + assetProfileApiUrl)
        .pipe(
          tap(res => {
            if (res) {
              this.assetProfileCache.set(ldcVal, res);
              if (environment.developmentMode) {
                // this.localStorage.
              }
            }
            console.log('fetched getAssetProfiles..');
          }),
          catchError(this.handleError('getAssetProfiles', null))
        );
    }
  }

  getLMCHAccountList(): Observable<any> {
    const jsonEndPoint = `/assets/resources/accounts/lmch_data.json`;
    return this.http.get<any>(jsonEndPoint)
      .pipe(
        tap(res => { console.log('fetched lmch details', res); }),
        catchError(this.handleError('LmchDetails', null))
      );
  }

  getEnmaxAccountList(): Observable<any> {
    const jsonEndPoint = `/assets/resources/accounts/enmax_data.json`;
    return this.http.get<any>(jsonEndPoint)
      .pipe(
        tap(res => { console.log('fetched lmch details', res); }),
        catchError(this.handleError('LmchDetails', null))
      );
  }

  getUserDetails(status: string, email: string): Observable<any> {
    let url = null;
    if (status === 'Pending') {
      url = `/lr/users?pageNum=-1`;
    } else if (status === 'Completed') {
      url = `/lr/users?registrationStatus=${status}&pageNum=-1`;
    }

    return this.http.get<any>(environment.globalIamEndPoint + url)
      .pipe(
        tap(res => { console.log('fetched user details', res); }),
        catchError(this.handleError('UserDetails', null))
      );
  }

  getUserDetailsByEmail(email: string): Observable<any> {
    let url = null;
    if (email !== null) {
      url = `/lr/users?email=${email}`;
    }

    return this.http.get<any>(environment.globalIamEndPoint + url)
      .pipe(
        tap(res => { console.log('fetched user details', res); }),
        catchError(this.handleError('UserDetails', null))
      );
  }

  updateUserPublicStatus(uid: string, accountId: number, event: string): Observable<any> {
    let url = null;
    const headers = { 'content-type': 'application/json' };

    if (event === 'Update Account' && accountId !== null) {
      url = `/lr/admin/user/${uid}/update?accountId=${accountId}&revokeAccess=false`;
    } else if (event === 'Revoke Public Access') {
      url = `/lr/admin/user/${uid}/update?revokeAccess=true`;
    } else if (event === 'Give Public Access') {
      url = `/lr/admin/user/${uid}/update`;
    }
    return this.http.post(environment.globalIamEndPoint + url, { headers: headers });
  }

  updateUserStatus(uid: string, accountId: number, event: string): Observable<any> {
    let url = null;
    let untag: boolean;
    const headers = { 'content-type': 'application/json' };

    if(event === 'Untag Account'){
      untag = true;
      url = `/lr/admin/user/${uid}/update?untag=${untag}`;
    } else if (event === 'Give Public Access') {
      url = `/lr/admin/user/${uid}/update`;
    } else if (event === 'Tag Account' || event === 'Update Account') {
      url = `/lr/admin/user/${uid}/update?accountId=${accountId}`;
    }
    return this.http.post(environment.globalIamEndPoint + url, { headers: headers })
    .pipe(
      tap(res => { console.log('updateUserStatus details', res); }),
      catchError(this.handleError('updateUserStatus', null))
    );
    /*** .pipe(
      tap(res => { console.log('fetched untagaccount details', res); },
      err => console.log('HTTP Error', err)),
      catchError(this.handleError('untagaccount Details', null))
    );***/
  }

  revokeAccess(uid): Observable<any> {
    const url = `/lr/admin/user/${uid}/revokeaccess`;
    const headers = { 'content-type': 'application/json' };
    return this.http.post(environment.globalIamEndPoint + url, { headers: headers })
    .pipe(
      tap(res => { console.log('revokeAccess details', res); },
      err => console.log('HTTP Error', err)),
      catchError(this.handleError('revokeAccess', null))
    );
  }

  tagUser(uid, accountId): Observable<any> {
    const body = { uuid: uid, accountId: accountId };
    const url = `/lr/admin/user/${uid}/update?accountId=${accountId}`;
    const headers = { 'content-type': 'application/json' };
    const requestBody = JSON.stringify(body);
    return this.http.post<any>(environment.globalIamEndPoint + url, requestBody, { headers: headers });
  }


  private handleError<T>(operation = 'operation', result?: T): any {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      // TODO: better job of transforming error for user consumption
      console.error(`${operation} failed: ${error.message}`);
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
