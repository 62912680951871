import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router, Event, NavigationEnd } from '@angular/router';
import { MenuItem } from 'src/app/framework/shared/model/menuitems';
import * as _ from 'underscore';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LandingPageComponent implements OnInit, OnDestroy {

  topMenuItems: MenuItem[];
  currentViewIndex: number;
  fullHeight: boolean;
  showCookieInfo = false;

  urlParams: {};
  routeSubscribtion: Subscription;

  constructor(private router: Router, private aRoute: ActivatedRoute, private cookieService: CookieService) { }

  ngOnInit(): void {
    this.topMenuItems = new Array<MenuItem>();
    this.topMenuItems.push(MenuItem.create(1, 'about_us', 'About Us', '/home/aboutus'));
    this.topMenuItems.push(MenuItem.create(2, 'our_solutions', 'Our Solutions', '/home/oursolutions'));
    this.topMenuItems.push(MenuItem.create(3, 'open_data', 'Open Data', '/home/opendata'));
    this.topMenuItems.push(MenuItem.create(4, 'login', 'Login', '/home/login'));
    this.loadDefaultMenu();

    console.log('this.router.routerState:::', this.router.routerState);
    if (this.router.routerState && this.router.routerState.snapshot) {
      if (this.router.routerState.snapshot.url.indexOf('/home/login') >= 0 ||
        this.router.routerState.snapshot.url.indexOf('/home/aboutus') >= 0) {
          this.fullHeight = true;
      } else {
        this.fullHeight = false;
      }
    }

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        if (this.router.url === '/home/login') {
          console.log('NavigationEnt to Login page:::');
          this.currentViewIndex = 3;
          this.fullHeight = true;
        } else if (this.router.url === '/home/aboutus') {
          this.fullHeight = true;
        } else {
          this.fullHeight = false;
        }
      }
    });

    this.routeSubscribtion = this.aRoute.queryParamMap.subscribe(params => {
      const qParam = 'params';
      if (params && !_.isEmpty(params[qParam])) {
        this.urlParams = params[qParam];
        if (this.urlParams) {
          console.log('::LandingHomePageComponent this.urlParams has been set:::', this.urlParams);
          const actionParam = 'action';
          const returnUrlParam = 'return_url';
          const loginMode = this.urlParams[actionParam] && this.urlParams[actionParam] === 'login';
          const returnUrl = this.urlParams[returnUrlParam];
          if (loginMode) {
            this.router.navigate(['/home/login']);
            this.currentViewIndex = 3;
          }
        }
      }
    });
  }

  private loadDefaultMenu(): void {
    const lthis = this;
    let sMenu = _.find(this.topMenuItems, (menu: MenuItem) => {
      return lthis.router.url === menu.action;
    });
    if (!sMenu) {
      sMenu = _.find(this.topMenuItems, (menu: MenuItem) => {
        return lthis.router.url.startsWith(menu.action);
      });
    }
    console.log('sMenu:::', sMenu);
    sMenu = sMenu || this.topMenuItems[0];
    this.currentViewIndex = _.indexOf(this.topMenuItems, sMenu);
    console.log('sMenu:::', sMenu, this.currentViewIndex, sMenu.action);
    if (sMenu) {
      this.router.navigate([sMenu.action]);
    }
  }


  changeView(event: any): void {
    this.currentViewIndex = event.index;
    const sMenu = this.topMenuItems[event.index];
    if (sMenu) {
      if (sMenu.name === 'login') {
        const userTokenValue: string = this.cookieService.get(environment.userToken);
        if (userTokenValue != null && userTokenValue !== '') {
          this.router.navigate(['/der']);
        } else {
          this.router.navigate([sMenu.action]);
          /* const appHomeUrl = window.top.location.origin;
          const iamUrl = environment.loginUrl + '?action=login&return_url=' + (appHomeUrl + '/login');
          this.router.navigate(['/external', { externalUrl: iamUrl }]); */
        }
      } else {
        this.router.navigate([sMenu.action]);
      }
    }
  }

  hideCookieInfo(): void {
    this.showCookieInfo = false;
  }

  ngOnDestroy(): void {
    if (this.routeSubscribtion) {
      this.routeSubscribtion.unsubscribe();
    }
  }

}
