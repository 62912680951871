import { Injectable } from '@angular/core';
import { IUser } from '../model/iuser';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';
import { User } from 'src/app/modules/auth/model/user';
import { CookieService } from 'ngx-cookie-service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class UserSessionService {

  private lloggedUser: BehaviorSubject<IUser> = new BehaviorSubject<IUser>(null);
  loggedUser = this.lloggedUser.asObservable();
  // loggedUser: IUser;
  currentUser: IUser;
  mobileMode = false;
  userDataLoaded = false;
  varCheck = 'new';

  constructor(private http: HttpClient, private cookieService: CookieService) {
    console.log('UserSessionService instance created::::::::::::::');
  }

  validateUserAccessToken(accessToken: string): Observable<any> {
    console.log('validate User accessToken:', accessToken);

    const authHeader = {
      headers: new HttpHeaders({ Authorization: 'Bearer ' + accessToken })
    };

    const userProfileEndPoint = `/identity/v2/auth/access_token/Validate?apikey=${environment.apiKey}`;
    const userFetch = this.http.get<any>(environment.iamEndPoint + userProfileEndPoint, authHeader);
    return userFetch.pipe(
      tap(user => { console.log('fetched token', user); }),
      catchError(this.handleError('user', null))
    );
  }

  /* async regenerateAccessToken(): Promise<any> {
    const refreshToken = this.cookieService.get(environment.refreshToken);
    console.log('get UserAccess token for refreshToken:', refreshToken);
    const userProfileEndPoint = `/identity/v2/manage/account/access_token/refresh?`
    + `refresh_token=${refreshToken}&apikey=${environment.apiKey}&apisecret=${environment.apiSecret}`;
    return await this.http.get<any>(environment.iamEndPoint + userProfileEndPoint).toPromise();
  } */

  getUserSession(): Observable<IUser> {
    console.log('this.varCheck:::::::::::::' + this.varCheck);
    const accesToken = this.cookieService.get(environment.userToken);
    console.log('get User session for:', accesToken);
    /* const userProfileEndPoint = `/identity/v2/auth/account?apiKey=${environment.apiKey}&access_token=${accesToken}`;
    // const userProfileEndPoint = `/api/v2/userprofile?access_token=${accesToken}`;
    const userFetch = this.http.get<User>(environment.iamEndPoint + userProfileEndPoint); */
    const userFetch = this.http.get<User>(environment.globalIamEndPoint + '/iam/management/whoami');
    return userFetch.pipe(
      tap(user => { console.log('fetched user', user); }),
      catchError(this.handleError('user', null))
    );
  }

  setLoggedUser(user: IUser): void {
    console.log('setLoggedUser:::', user);
    this.varCheck = 'updated';
    if (user) {
      this.currentUser = user;
      this.userDataLoaded = true;
    } else {
      this.currentUser = null;
      this.userDataLoaded = false;
    }
    this.lloggedUser.next(user);
  }

  invalidateAccessToken(): Observable<any> {
    const accesToken = this.cookieService.get(environment.userToken);
    console.log('get User session for:', accesToken);
    const logoutEndPoint = `/identity/v2/auth/access_token/InValidate?access_token=${accesToken}&apiKey=${environment.apiKey}`;
    const userFetch = this.http.get<any>(environment.iamEndPoint + logoutEndPoint);
    return userFetch.pipe(
      tap(res => { console.log('invalidateAccessToken', res); }),
      catchError(this.handleError('invalidateAccessToken', null))
    );
  }

  getCustomerAccountDetails(): Observable<any> {
    const jsonEndPoint = `/assets/resources/myassets/customer_accounts.json`;
    return this.http.get<any>(jsonEndPoint)
      .pipe(
        tap(res => { console.log('fetched customer details', res); }),
        catchError(this.handleError('CustomerDetails', null))
      );
  }

  private handleError<T>(operation = 'operation', result?: T): any {
    return (error: any): Observable<T> => {
      console.error(operation + ' - failed:: ', error); // log to console instead
      // console.error(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

}
