<div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="space-between center" class="container1">
	<div fxFlex="100" fxLayoutAlign="center" class="bg-white fixed-top">
		<div fxLayout="row wrap" fxLayoutAlign="space-between" class="px-3 pt-2 page-header">
			<div fxLayout="row" fxLayoutAlign="start center" class="page-title"><a class="image-box icon-l2l" href="/"
					title="L2L Open Data Platform"></a><span class="font-weight-bold ml-3">Open
					Data Platform</span></div>
			<mat-tab-group fxFlex.lt-md="100" mat-align-tabs="end" animationDuration="0ms" class="mat-group-custom mat-tab-label-custom"
				(selectedTabChange)="changeView($event)" [selectedIndex]="currentViewIndex">
				<mat-tab *ngFor="let menu of topMenuItems;let index = index; let last = last;">
					<ng-template mat-tab-label>
						<span mat-icon-button style="font-weight: bold;">{{menu.label}}</span>
					</ng-template>
				</mat-tab>
			</mat-tab-group>
		</div>
	</div>
	<div class="w-100 tab-content bg-white">
		<router-outlet fxFill class="router-flex-c embed-responsive-item"></router-outlet>
	</div>
	<app-footer fxFlex="100"></app-footer>
</div>