import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieService } from 'ngx-cookie-service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ExternalLibModule } from './framework/external/external-lib.module';
import { HomeComponent } from './home/home.component';
import { LayoutModule } from './modules/layout/layout.module';
import { SharedModule } from './framework/shared/shared.module';
import { GoogleMapsModule } from '@angular/google-maps';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { OurSolutionsComponent } from './components/our-solutions/our-solutions.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { OpenDataComponent } from './components/open-data/open-data.component';
/* import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as Highcharts from 'highcharts';
import * as more from 'highcharts/highcharts-more.src';
import * as exporting from 'highcharts/modules/exporting.src';
import patternFill from 'highcharts/modules/pattern-fill';
// patternFill(Highcharts);
import { NgxGaugeModule } from 'ngx-gauge'; */
import { DecimalPipe } from '@angular/common';
import { PortalService } from './service/portal.service';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { UiFrameworkLibModule, AngularMaterialModule } from '@londonhydro/ui-framework-lib';
import { UiUtilLibraryModule } from '@londonhydro/ui-framework-lib/ui-util-lib';
import { WidgetLibraryModule, MessageUtilLibraryModule } from '@londonhydro/ui-framework-lib/widget-lib';
import { SafeUrlPipe } from './framework/ui-lib/pipes/safeurl.pipe';
import { AdminService } from './service/admin.service';
import { SecondassetHomeComponent } from './modules/secondasset/components/secondasset-home/secondasset-home.component';
import { NewsolarassetComponent } from './modules/secondasset/components/newsolarasset/newsolarasset.component';
import { NewevassetComponent } from './modules/secondasset/components/newevasset/newevasset.component';
import { BatteryassetComponent } from './modules/secondasset/components/batteryasset/batteryasset.component';
import { ThrmostatassetComponent } from './modules/secondasset/components/thrmostatasset/thrmostatasset.component';
import { LdcEnergyWidgetComponent } from './framework/ui-lib/widget/mix/ldc-energy-widget/ldc-energy-widget.component';
import { DemoComponent } from './components/demo/demo.component';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutUsComponent,
    OurSolutionsComponent,
    LandingPageComponent,
    LoginPageComponent,
    OpenDataComponent,
    SafeUrlPipe,
    SecondassetHomeComponent,
    NewsolarassetComponent,
    NewevassetComponent,
    BatteryassetComponent,
    ThrmostatassetComponent,
    LdcEnergyWidgetComponent,
    DemoComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    UiFrameworkLibModule,
    AngularMaterialModule,
    UiUtilLibraryModule,
    WidgetLibraryModule,
    MessageUtilLibraryModule,
    ExternalLibModule,
    FormsModule,
    ReactiveFormsModule,
    GoogleMapsModule,
    SharedModule.forRoot(),
    LayoutModule,
    NgxChartsModule
  ],
  providers: [CookieService, PortalService, AdminService, DecimalPipe, SafeUrlPipe ],
  // { provide: HIGHCHARTS_MODULES, useFactory: () => [more, exporting, patternFill] }
  bootstrap: [AppComponent]
})
export class AppModule { }
