import { Component, OnInit, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'underscore';
import { MenuService } from 'src/app/framework/shared/service/menu.service';
import { MenuItem } from 'src/app/framework/shared/model/menuitems';
import { Subscription } from 'rxjs';
import { UserSessionService } from 'src/app/framework/shared/service/user-session.service';
import { IUser } from 'src/app/framework/shared/model/iuser';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit, OnDestroy {

  private masterMenuList: MenuItem[];
  userMenuList: MenuItem[];

  selectedMenu: MenuItem;
  userSubscription: Subscription;
  loggedUser: IUser;

  openMode = 'side';
  showNav = true;
  mobileView = false;
  mobileClass: string;

  @Input()
  set menuItems(menuItems: MenuItem[]) {
    if (this.masterMenuList !== menuItems) {
      this.masterMenuList = menuItems;
      this.loadUserMenu();
    }
  }

  @Input()
  set open(showNav: boolean) {
    this.showNav = showNav;
    this.cdRef.detectChanges();
  }

  @Input()
  set mode(openMode: string) {
    this.openMode = openMode;
    this.cdRef.detectChanges();
  }

  @Input()
  set mobileMode(mobileMode: boolean) {
    this.mobileView = mobileMode;
    this.mobileClass = mobileMode ? 'is-mobile' : '';
    this.cdRef.detectChanges();
  }

  constructor(private router: Router, private menuService: MenuService, private userSessionService: UserSessionService,
              private cdRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.userSubscription = this.userSessionService.loggedUser.subscribe(user => {
      if (user) {
        user.customFields = user.customFields || {};
        if (user !== this.loggedUser) {
          this.loggedUser = _.extend({}, user);
          this.loadUserMenu();
        }
      }
    });
  }

  private loadUserMenu(): void {
    if (this.masterMenuList && this.loggedUser) {
      let userRoles = this.loggedUser.roles;
      if (!userRoles || _.isEmpty(userRoles)) {
        userRoles = ['USER'];
      }
      this.userMenuList = _.filter(this.masterMenuList, (menu: MenuItem) =>
        // menu.role === null || (menu.role && _.contains(userRoles, menu.role))
        menu.role === null || (menu.role && !_.isEmpty(_.intersection(userRoles, menu.role.split(','))))
      );

      this.menuService.selectedMenu.subscribe(sMenu => {
        if (sMenu) {
          this.selectedMenu = sMenu;
        }
      });
      if (!this.selectedMenu) {
        if (this.userMenuList && !_.isEmpty(this.userMenuList)) {
          this.menuService.changeActiveMenu(this.userMenuList[0]);
        }
      }
      this.cdRef.detectChanges();
    }
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  navigateToMenu(menu: MenuItem): void {
    if (menu !== this.selectedMenu && (this.selectedMenu && this.selectedMenu.name !== menu.name)) {
      this.selectedMenu = menu;
      this.menuService.changeActiveMenu(this.selectedMenu);
      this.router.navigate([menu.action]);
    } else {
      console.log('User is trying to load same menu...');
    }
  }

}
