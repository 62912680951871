
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { IUser } from 'src/app/framework/shared/model/iuser';
import { MenuItem } from 'src/app/framework/shared/model/menuitems';
import { UserSessionService } from 'src/app/framework/shared/service/user-session.service';
import * as _ from 'underscore';

@Component({
  selector: 'app-secondasset-home',
  templateUrl: './secondasset-home.component.html',
  styleUrls: ['./secondasset-home.component.scss']
})
export class SecondassetHomeComponent implements OnInit {

  topMenuItems: MenuItem[];

  loggedUser: IUser;
  hasAdminRole = false;
  userSubscription: Subscription;

  constructor(private router: Router, private userSessionService: UserSessionService) { }

  ngOnInit(): void {
    this.userSubscription = this.userSessionService.loggedUser.subscribe(user => {
      if (user) {
        user.customFields = user.customFields || {};
        if (user !== this.loggedUser || (this.loggedUser.customFields.accountId !== user.customFields.accountId)) {
          console.log('change in user detected..loading data ...');
          this.loggedUser = user;
          this.loadPageMenu();
        }
      }
    });
  }

  private loadPageMenu(): void {
    this.topMenuItems = new Array<MenuItem>();
    if (this.loggedUser.roles && !_.isEmpty(this.loggedUser.roles)) {
      this.hasAdminRole = _.contains(this.loggedUser.roles, 'ADMIN');
    }
    console.log('AdminHomeComponent::: this.router.url::' + this.router.url);

    this.topMenuItems.push(MenuItem.create(1, 'solarasset', 'Solar', '/secondasset/solarasset'));
    this.topMenuItems.push(MenuItem.create(2, 'batteryasset', 'Battery', '/secondasset/batteryasset'));
    this.topMenuItems.push(MenuItem.create(3, 'evasset', 'EV', '/secondasset/evasset'));
    this.topMenuItems.push(MenuItem.create(4, 'thermostatasset', 'THERMOSTAT', '/secondasset/thermostatasset'));

    this.loadDefaultMenu();
  }

  private loadDefaultMenu(): void {
    const lthis = this;
    let sMenu = _.find(this.topMenuItems, (menu: MenuItem) => {
      return lthis.router.url === menu.action;
    });
    if (!sMenu) {
      sMenu = _.find(this.topMenuItems, (menu: MenuItem) => {
        return lthis.router.url.startsWith(menu.action);
      });
    }
    sMenu = sMenu || this.topMenuItems[0];
    if (sMenu) {
      this.router.navigate([sMenu.action]);
    }
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

}
