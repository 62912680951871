export const environment = {
developmentMode:false,
production:false,
apiKey:'c4fc8b11-bf33-4bfb-aa33-892f23fa9cc8',
apiSecret:'2c9892e9-ed13-4e2a-94db-e0f90afdbdd9',
derExplorerUrl:'https://derexplorer-dot-londonhydro-pfc-dev.appspot.com',
derLiveMsEndPoint:'https://energyusage-dot-londonhydro-pfc-dev.appspot.com',
derMsEndPoint:'https://solar-dot-londonhydro-pfc-dev.appspot.com',
derPublicLiveMsEndPoint:'https://energyusagev2-dot-londonhydro-pfc-dev.uc.r.appspot.com',
globalIamEndPoint:'https://loginradius-dot-londonhydro-pfc-dev.uc.r.appspot.com',
gridserviceUrl:'https://gridserviceadmin.firebaseapp.com/gridservice',
iamEndPoint:'https://api.loginradius.com',
liveDashboardUrl:'https://realtimedashboard-dot-londonhydro-pfc-dev.appspot.com/live',
loginRadiusEndPoint:'https://loginradius-dot-londonhydro-pfc-dev.uc.r.appspot.com',
loginUrl:'https://authdev.londonhydro.com/auth.aspx',
userToken:'dev-uiam-token',
};
