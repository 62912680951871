import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import * as moment from 'moment-timezone';
import { UIUtil } from '@londonhydro/ui-framework-lib/ui-util-lib';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PortalService {

  public eventSource: EventSource;

  constructor(private http: HttpClient) { }
  getLdcAssets(): Observable<any> {
    const jsonEndPoint = `/assets/resources/mockdata/accounts.json`;
    return this.http.get<any>(jsonEndPoint)
      .pipe(
        tap(res => { console.log('fetched getLdcAssets', res); }),
        catchError(this.handleError('getLdcAssets', null))
      );
  }
  getSolutionApps(): Observable<any> {
    const jsonEndPoint = `/assets/config/solution-apps-data-config.json`;
    return this.http.get<any>(jsonEndPoint)
      .pipe(
        tap(res => { console.log('fetched MatCardDataConfig', res); }),
        catchError(this.handleError('MatCardDataConfig', null))
      );
  }

  getDerProfileForProgram(startDate: Date, endDate: Date): Observable<any> {
    let derProfileApiUrl: string;
    const iStartDate = moment.tz(startDate, UIUtil.UiTimeZone).format(UIUtil.UiDateApiInputFormat);
    const iEndDate = moment.tz(endDate, UIUtil.UiTimeZone).format(UIUtil.UiDateApiInputFormat);
    const period = 'Monthly';
    derProfileApiUrl = `/public/derprofile/drprogram?startDate=${iStartDate}&endDate=${iEndDate}&period=${period}`;
    console.log('derProfileApiUrl:::' + derProfileApiUrl);

    return this.http.get<any>(environment.derMsEndPoint + derProfileApiUrl)
      .pipe(
        tap(res => { console.log('fetched Solar daily consumption', res); }),
        catchError(this.handleError('Solar daily consumption', null))
      );
  }

  getSolarAssetList(): Observable<any> {
    const jsonEndPoint = `/derprofile/smartHomeProfiles`;
    return this.http.get<any>(environment.derMsEndPoint + jsonEndPoint)
      .pipe(
        tap(res => { console.log('fetched solar asset details', res); }),
        catchError(this.handleError('SADetails', null))
      );
  }

  isLiveStreamSupported(): boolean {
    let isSupported = true;
    let es: EventSource;
    try {
      es = new EventSource('/');
    }
    catch (error) {
      isSupported = false;
      console.log('exception:', error);
    }
    if (es) {
      es.close();
    }
    return isSupported;
  }

  getLiveKpiSummary(assetType: string): Observable<any> {
    const liveKpiDataUrl = `/kpi/live?assetType=${assetType}`;
    return new Observable<any>((observer) => {
      this.eventSource = new EventSource(environment.derPublicLiveMsEndPoint + liveKpiDataUrl);
      // this.eventSource = new EventSourcePolyfill(environment.derLiveMsEndPoint + liveKpiDataUrl, eventSourceInitDict);
      this.eventSource.onmessage = (event) => {
        // console.log('Received event: ', event);
        const json = JSON.parse(event.data);
        observer.next(json);
      };
      this.eventSource.onerror = (error) => {
        if (this.eventSource.readyState === 0) {
          console.log('The stream has been closed by the server.');
          this.eventSource.close();
          // observer.complete();
          observer.error({ status: 'Closed', desc: 'The stream has been closed by the server.' });
        } else {
          observer.error('EventSource error: ' + error);
        }
      };
    });
  }

  stopLiveStream(): void {
    if (this.eventSource) {
      console.log('Going to stop the live stream.' + this.eventSource.readyState);
      this.eventSource.close();
      console.log('Live stream has been stopped successfuly.' + this.eventSource.readyState);
    } else {
      console.log('No active live stream.');
    }
  }

  private handleError<T>(operation = 'operation', result?: T): any {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      // TODO: better job of transforming error for user consumption
      console.error(`${operation} failed: ${error.message}`);
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
