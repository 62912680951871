<div fxLayout="row wrap" fxFlex="100" class="mx-2">
  <app-header fxFlex="100"></app-header>
  <!-- <app-page-title [showMenuOption]="mobileQuery.matches?true:false" (toogleMenu)="toogleMenu($event)"></app-page-title> -->
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="start stretch">
    <app-side-menu *ngIf="showSideMenu" [menuItems]="sideMenuList" [mode]="menuMode" [(mobileMode)]="mobileMode"
      [(open)]="toogleMenuView" ></app-side-menu>
    <div fxFlex fxLayout="column" fxLayoutAlign="start stretch" class="full-min-height">
      <!-- {{mobileMode}}-{{menuMode}}-{{showSideMenu}}-{{toogleMenuView}} -->
      <app-page-title [(showMenuOption)]="mobileMode" (toogleMenu)="toogleMenu($event)">
      </app-page-title>
      <app-top-menu *ngIf="topMenu && topMenu.length > 0" [menuItems]="topMenu"></app-top-menu>
      <div fxFlex="100" class="w-100 h-100 bg-transparent" [ngClass]="'p-4'" [ngClass.lt-md]="'p-2'">
        <router-outlet *ngIf="!showCustomContent" fxFill class="router-flex"></router-outlet>
        <ng-container *ngIf="showCustomContent" fxFlex="100" class="full-min-height">
          <ng-content class="w-100"></ng-content>
        </ng-container>
      </div>
    </div>
  </div>
  <app-footer fxFlex="100"></app-footer>
</div>