import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
// import * as moment from 'moment-timezone';

@Injectable() // { providedIn: 'root'}
export class HttpIntercepterService implements HttpInterceptor {

  constructor(private router: Router, private cookieService: CookieService) { }

  private setDefaultContentType(request: HttpRequest<any>): HttpRequest<any> {
    if (!request.headers.has('Content-Type')) {
      request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
    }
    return request;
  }

  private applyCredentials(request: HttpRequest<any>): HttpRequest<any> {
    const userAccessToken = this.cookieService.get(environment.userToken);
    return request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + userAccessToken) });
  }

 /*  private async getTokenDetails(): Promise<string> {
    const tokenDetails = await this.userSession.regenerateAccessToken();
    if (tokenDetails && !tokenDetails.ErrorCode) {
      this.cookieService.set(environment.userToken, tokenDetails.access_token, moment(tokenDetails.expires_in).toDate());
      this.cookieService.set(environment.refreshToken, tokenDetails.refresh_token);
      return tokenDetails.access_token;
    }
    return null;
  } */

  // intercept request and add token
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log('----Making HTTP request----', request);
    request = this.setDefaultContentType(request);
    if (!request.url.startsWith(environment.iamEndPoint)) {
      if (this.cookieService.check(environment.userToken)) {
        const userAccessToken = this.cookieService.get(environment.userToken);
        if (userAccessToken) {
          request = this.applyCredentials(request);
        }
      }
    } else {

    }
    return next.handle(request)
      .pipe(
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            console.log('response event--->>>', event);
          }
          return event;
        }),
        catchError((error: HttpErrorResponse) => {
          console.log('response error--->>>', error);
          if (error instanceof HttpErrorResponse) {
            if (error.status === 401) {
              this.redirctToLogin();
            }
          }
          return throwError(error);
        }),
      );
  }

  private redirctToLogin(): void {
    console.log('redirctToLogin::::::::::::' + this.router.url);
    if (this.router.url.indexOf('/home') < 0) {
      this.cookieService.set('lastRoute', this.router.url, null, '/');
    }
    const appHomeUrl = window.top.location.origin;
    console.log('appHomeUrl::', appHomeUrl);
    const homeUrl = appHomeUrl + '?action=login&return_url=' + this.router.url;
    this.router.navigate(['/external', { externalUrl: homeUrl }]);
  }
}
