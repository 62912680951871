import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy, Input, ChangeDetectorRef, QueryList, ViewChildren } from '@angular/core';
import * as _ from 'underscore';
import { WidgetConfig, WidgetData } from '@londonhydro/ui-framework-lib/widget-lib';
// import { Grid } from '../../config/utility-widgets';
// import { Solar } from '../../config/solar-widgets';
declare var LeaderLine: any;

export class EV extends WidgetData {
  constructor(value?: number, demand?: boolean) {
    const name = 'EV';
    let unit = demand ? 'kW' : 'kWh';
    let mv = value;
    if (value && value > 1000) {
      mv = value / 1000;
      unit = demand ? 'MW' : 'MWh';
    }
    super(name, mv, unit, 'icon-evc', 'info-tile small-gauge no-box-shadow p-0');
    this.extraConfig = {};
    this.extraConfig.gaugeColor = '#045097';
    this.extraConfig.gaugeBgColor = '#045097';
    this.extraConfig.gaugeSize = 100;
  }
}

export class Home extends WidgetData {
  constructor(value?: number, demand?: boolean) {
    const name = ' ';
    let unit = demand ? 'kW' : 'kWh';
    let mv = value;
    if (value && value > 1000) {
      mv = value / 1000;
      unit = demand ? 'MW' : 'MWh';
    }
    super(name, mv, unit, 'icon-home', 'info-tile small-gauge no-box-shadow p-0');
    this.extraConfig = {};
    this.extraConfig.gaugeSize = 100;
    this.extraConfig.gaugeColor = '#AA0000';
    this.extraConfig.gaugeBgColor = '#AA0000';
  }
}


export class Solar extends WidgetData {
  constructor(value?: number, demand?: boolean) {
    const name = ' ';
    let unit = demand ? 'kW' : 'kWh';
    let mv = value;
    if (value && value > 1000) {
      mv = value / 1000;
      unit = demand ? 'MW' : 'MWh';
    }
    super(name, mv, unit, 'icon-solar-battery', 'info-tile small-gauge no-box-shadow');
    this.extraConfig = {};
    this.extraConfig.gaugeColor = '#48CC93';
    this.extraConfig.gaugeBgColor = '#48CC93';
    this.extraConfig.gaugeSize = 100;
  }
}

export class Grid extends WidgetData {
  constructor(value?: number, demand?: boolean) {
    const name = ' ';
    let unit = demand ? 'kW' : 'kWh';
    let mv = value;
    if (value && value > 1000) {
      mv = value / 1000;
      unit = demand ? 'MW' : 'MWh';
    }
    super(name, mv, unit, 'icon-grid-battery', 'info-tile small-gauge no-box-shadow');
    this.extraConfig = {};
    this.extraConfig.gaugeColor = '#FFFFFF';
    this.extraConfig.gaugeBgColor = '#FFFFFF';
    this.extraConfig.gaugeSize = 100;
  }
}

@Component({
  selector: 'app-ldc-energy-widget',
  templateUrl: './ldc-energy-widget.component.html',
  styleUrls: ['./ldc-energy-widget.component.scss']
})
export class LdcEnergyWidgetComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('solarPoint1', { read: ElementRef }) solarPoint1: ElementRef;
  @ViewChild('solarPoint2', { read: ElementRef }) solarPoint2: ElementRef;
  @ViewChild('solarPoint3', { read: ElementRef }) solarPoint3: ElementRef;
  @ViewChild('solarPoint4', { read: ElementRef }) solarPoint4: ElementRef;
  @ViewChild('solarPoint5', { read: ElementRef }) solarPoint5: ElementRef;
  @ViewChild('solarPoint6', { read: ElementRef }) solarPoint6: ElementRef;
  @ViewChild('solarPoint7', { read: ElementRef }) solarPoint7: ElementRef;
  @ViewChild('solarPoint8', { read: ElementRef }) solarPoint8: ElementRef;
  @ViewChild('solarPoint9', { read: ElementRef }) solarPoint9: ElementRef;
  @ViewChild('solarPoint10', { read: ElementRef }) solarPoint10: ElementRef;

  @ViewChild('homePoint1', { read: ElementRef }) homePoint1: ElementRef;
  @ViewChild('homePoint2', { read: ElementRef }) homePoint2: ElementRef;
  @ViewChild('homePoint3', { read: ElementRef }) homePoint3: ElementRef;
  @ViewChild('homePoint4', { read: ElementRef }) homePoint4: ElementRef;
  @ViewChild('homePoint5', { read: ElementRef }) homePoint5: ElementRef;
  @ViewChild('homePoint6', { read: ElementRef }) homePoint6: ElementRef;
  @ViewChild('homePoint7', { read: ElementRef }) homePoint7: ElementRef;
  @ViewChild('homePoint8', { read: ElementRef }) homePoint8: ElementRef;
  @ViewChild('homePoint9', { read: ElementRef }) homePoint9: ElementRef;
  @ViewChild('homePoint10', { read: ElementRef }) homePoint10: ElementRef;

  @ViewChild('evPoint1', { read: ElementRef }) evPoint1: ElementRef;
  @ViewChild('evPoint2', { read: ElementRef }) evPoint2: ElementRef;
  @ViewChild('evPoint3', { read: ElementRef }) evPoint3: ElementRef;
  @ViewChild('evPoint4', { read: ElementRef }) evPoint4: ElementRef;
  @ViewChild('evPoint5', { read: ElementRef }) evPoint5: ElementRef;

  @ViewChild('gridPointR', { read: ElementRef }) gridPointR: ElementRef;
  @ViewChild('gridPointD', { read: ElementRef }) gridPointD: ElementRef;

  solarLine: any;
  gridLine: any;

  SolarWidget1: WidgetConfig;
  SolarWidget2: WidgetConfig;
  SolarWidget3: WidgetConfig;
  SolarWidget4: WidgetConfig;
  SolarWidget5: WidgetConfig;
  SolarWidget6: WidgetConfig;
  SolarWidget7: WidgetConfig;
  SolarWidget8: WidgetConfig;
  SolarWidget9: WidgetConfig;
  SolarWidget10: WidgetConfig;

  HomeWidget1: WidgetConfig;
  HomeWidget2: WidgetConfig;
  HomeWidget3: WidgetConfig;
  HomeWidget4: WidgetConfig;
  HomeWidget5: WidgetConfig;
  HomeWidget6: WidgetConfig;
  HomeWidget7: WidgetConfig;
  HomeWidget8: WidgetConfig;
  HomeWidget9: WidgetConfig;
  HomeWidget10: WidgetConfig;

  EvWidget1: WidgetConfig;
  EvWidget2: WidgetConfig;
  EvWidget3: WidgetConfig;
  EvWidget4: WidgetConfig;
  EvWidget5: WidgetConfig;

  GridWidgetR: WidgetConfig;
  GridWidgetD: WidgetConfig;

  solarLine1: any;
  solarLine2: any;
  solarLine3: any;
  solarLine4: any;
  solarLine5: any;
  solarLine6: any;
  solarLine7: any;
  solarLine8: any;
  solarLine9: any;
  solarLine10: any;

  gridLine1: any;
  gridLine2: any;
  gridLine3: any;
  gridLine4: any;
  gridLine5: any;
  gridLine6: any;
  gridLine7: any;
  gridLine8: any;
  gridLine9: any;
  gridLine10: any;

  evLine1: any;
  evLine2: any;
  evLine3: any;
  evLine4: any;
  evLine5: any;

  solarGridLine: any;

  solarLayout = 'row';
  _demand = false;
  _animation = true;

  solarAssets: Array<any>;
  evAssets: Array<any>;

  @Input()
  set animation(v: boolean) {
    this._animation = v;
  }

  @Input()
  set demand(v: boolean) {
    this._demand = v;
  }

  @Input()
  set widgetData(v: Array<any>) {
    if (v != null && !_.isEmpty(v)) {
      const solarAssets = _.filter(v, (asset: any) => asset.assetType === 'SOLAR');
      const evAssets = _.filter(v, (asset: any) => asset.assetType === 'EV');

      this.initializeWidgets(solarAssets, evAssets);

      setTimeout(() => {
        this.renderSolarGridLines();
      }, 500);
    }
  }

  constructor(private cdRef: ChangeDetectorRef) {
    this.initializeWidgets();
  }

  initializeWidgets(solarAssets?: Array<any>, evAssets?: Array<any>): void {
    this.solarAssets = solarAssets || new Array();
    this.evAssets = evAssets || new Array();

    if (this.solarAssets.length < 10) {
      for (let i = this.solarAssets.length; i < 10; i++) {
        this.solarAssets.push({
          accountId: null,
          assetType: 'SOLAR',
          consumption: null,
          generation: null,
          netConsumption: null,
          netGeneration: null,
          source: null
        });
      }
    }

    if (this.evAssets.length < 10) {
      for (let i = this.evAssets.length; i < 10; i++) {
        this.evAssets.push({
          accountId: null,
          assetType: 'EV',
          consumption: null,
          generation: null,
          netConsumption: null,
          netGeneration: null,
          source: null
        });
      }
    }

    this.SolarWidget1 = new Solar(this.solarAssets[0].generation, this._demand);
    this.SolarWidget2 = new Solar(this.solarAssets[1].generation, this._demand);
    this.SolarWidget3 = new Solar(this.solarAssets[2].generation, this._demand);
    this.SolarWidget4 = new Solar(this.solarAssets[3].generation, this._demand);
    this.SolarWidget5 = new Solar(this.solarAssets[4].generation, this._demand);
    this.SolarWidget6 = new Solar(this.solarAssets[5].generation, this._demand);
    this.SolarWidget7 = new Solar(this.solarAssets[6].generation, this._demand);
    this.SolarWidget8 = new Solar(this.solarAssets[7].generation, this._demand);
    this.SolarWidget9 = new Solar(this.solarAssets[8].generation, this._demand);
    this.SolarWidget10 = new Solar(this.solarAssets[9].generation, this._demand);

    /* this.SolarWidget1.name = solarAssets[0].source || 'Solar';
    this.SolarWidget2.name = solarAssets[1].source || 'Solar';
    this.SolarWidget3.name = solarAssets[2].source || 'Solar';
    this.SolarWidget4.name = solarAssets[3].source || 'Solar';
    this.SolarWidget5.name = solarAssets[4].source || 'Solar';
    this.SolarWidget6.name = solarAssets[5].source || 'Solar';
    this.SolarWidget7.name = solarAssets[6].source || 'Solar';
    this.SolarWidget8.name = solarAssets[7].source || 'Solar';
    this.SolarWidget9.name = solarAssets[8].source || 'Solar';
    this.SolarWidget10.name = solarAssets[9].source || 'Solar'; */

    this.HomeWidget1 = new Home(this.solarAssets[0].consumption, this._demand);
    this.HomeWidget2 = new Home(this.solarAssets[1].consumption, this._demand);
    this.HomeWidget3 = new Home(this.solarAssets[2].consumption, this._demand);
    this.HomeWidget4 = new Home(this.solarAssets[3].consumption, this._demand);
    this.HomeWidget5 = new Home(this.solarAssets[4].consumption, this._demand);
    this.HomeWidget6 = new Home(this.solarAssets[5].consumption, this._demand);
    this.HomeWidget7 = new Home(this.solarAssets[6].consumption, this._demand);
    this.HomeWidget8 = new Home(this.solarAssets[7].consumption, this._demand);
    this.HomeWidget9 = new Home(this.solarAssets[8].consumption, this._demand);
    this.HomeWidget10 = new Home(this.solarAssets[9].consumption, this._demand);

    /* this.HomeWidget1.name = solarAssets[0].source || 'Home';
    this.HomeWidget2.name = solarAssets[1].source || 'Home';
    this.HomeWidget3.name = solarAssets[2].source || 'Home';
    this.HomeWidget4.name = solarAssets[3].source || 'Home';
    this.HomeWidget5.name = solarAssets[4].source || 'Home';
    this.HomeWidget6.name = solarAssets[5].source || 'Home';
    this.HomeWidget7.name = solarAssets[6].source || 'Home';
    this.HomeWidget8.name = solarAssets[7].source || 'Home';
    this.HomeWidget9.name = solarAssets[8].source || 'Home';
    this.HomeWidget10.name = solarAssets[9].source || 'Home'; */

    this.EvWidget1 = new EV(this.evAssets[0].consumption, this._demand);
    this.EvWidget2 = new EV(this.evAssets[1].consumption, this._demand);
    this.EvWidget3 = new EV(this.evAssets[2].consumption, this._demand);
    this.EvWidget4 = new EV(this.evAssets[3].consumption, this._demand);
    this.EvWidget5 = new EV(this.evAssets[4].consumption, this._demand);

    this.EvWidget1.name = this.evAssets[0].source || 'EV';
    this.EvWidget2.name = this.evAssets[1].source || 'EV';
    this.EvWidget3.name = this.evAssets[2].source || 'EV';
    this.EvWidget4.name = this.evAssets[3].source || 'EV';
    this.EvWidget5.name = this.evAssets[4].source || 'EV';

    const totalRecieved = _.reduce(this.solarAssets, (memo, asset) => (memo + asset.generation || 0), 0);
    this.GridWidgetR = new Solar(totalRecieved, this._demand);
    this.GridWidgetR.name = 'DER Bank';
    this.GridWidgetR.widgetClass += ' widget-grid';
    this.GridWidgetR.iconClass += ' widget-icon-medium bg-size-auto';
    this.GridWidgetR.extraConfig.gaugeColor = '#FFFFFF';
    this.GridWidgetR.extraConfig.gaugeBgColor = '#FFFFFF';
    this.GridWidgetR.extraConfig.gaugeSize = 150;

    const totalDelivered = _.reduce(this.solarAssets, (memo, asset) => (memo + asset.consumption || 0), 0);
    this.GridWidgetD = new Grid(totalDelivered, this._demand);
    this.GridWidgetD.name = 'GRID';
    this.GridWidgetD.widgetClass += ' widget-grid';
    this.GridWidgetD.iconClass += ' widget-icon-medium bg-size-auto';
    this.GridWidgetD.extraConfig.gaugeSize = 150;
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.renderSolarGridLines();
    }, 500);
  }

  private renderSolarGridLines(): void {
    this.removeGridLines();

    this.solarLine1 = new LeaderLine(LeaderLine.pointAnchor(this.solarPoint1.nativeElement, {
      x: '100%',
      y: '35%'
    }),
      LeaderLine.pointAnchor(this.gridPointR.nativeElement, {
        x: 0,
        y: '20%'
      }), { path: 'fluid', color: '#48CC93b5', dash: { animation: (this._animation && this.SolarWidget1.value) } });

    this.solarLine2 = new LeaderLine(LeaderLine.pointAnchor(this.solarPoint2.nativeElement, {
      x: '100%',
      y: '35%'
    }),
      LeaderLine.pointAnchor(this.gridPointR.nativeElement, {
        x: 0,
        y: '28%'
      }), { path: 'fluid', color: '#48CC93b5', dash: { animation: (this._animation && this.SolarWidget2.value) } });

    this.solarLine3 = new LeaderLine(LeaderLine.pointAnchor(this.solarPoint3.nativeElement, {
      x: '100%',
      y: '35%'
    }),
      LeaderLine.pointAnchor(this.gridPointR.nativeElement, {
        x: 0,
        y: '36%'
      }), { path: 'fluid', color: '#48CC93b5', dash: { animation: (this._animation && this.SolarWidget3.value) } });

    this.solarLine4 = new LeaderLine(LeaderLine.pointAnchor(this.solarPoint4.nativeElement, {
      x: '100%',
      y: '35%'
    }),
      LeaderLine.pointAnchor(this.gridPointR.nativeElement, {
        x: 0,
        y: '44%'
      }), { path: 'fluid', color: '#48CC93b5', dash: { animation: (this._animation && this.SolarWidget4.value) } });

    this.solarLine5 = new LeaderLine(LeaderLine.pointAnchor(this.solarPoint5.nativeElement, {
      x: '100%',
      y: '35%'
    }),
      LeaderLine.pointAnchor(this.gridPointR.nativeElement, {
        x: 0,
        y: '52%'
      }), { path: 'fluid', color: '#48CC93b5', dash: { animation: (this._animation && this.SolarWidget5.value) } });


    this.solarLine6 = new LeaderLine(LeaderLine.pointAnchor(this.solarPoint6.nativeElement, {
      x: '50%',
      y: '60%'
    }),
      LeaderLine.pointAnchor(this.gridPointR.nativeElement, {
        x: '25%',
        y: 0
      }), { path: 'fluid', color: '#48CC93b5', dash: { animation: (this._animation && this.SolarWidget6.value) } });

    this.solarLine7 = new LeaderLine(LeaderLine.pointAnchor(this.solarPoint7.nativeElement, {
      x: '50%',
      y: '60%'
    }),
      LeaderLine.pointAnchor(this.gridPointR.nativeElement, {
        x: '35%',
        y: 0
      }), { path: 'fluid', color: '#48CC93b5', dash: { animation: (this._animation && this.SolarWidget7.value) } });

    this.solarLine8 = new LeaderLine(LeaderLine.pointAnchor(this.solarPoint8.nativeElement, {
      x: '50%',
      y: '60%'
    }),
      LeaderLine.pointAnchor(this.gridPointR.nativeElement, {
        x: '45%',
        y: 0
      }), { path: 'fluid', color: '#48CC93b5', dash: { animation: (this._animation && this.SolarWidget8.value) } });

    this.solarLine9 = new LeaderLine(LeaderLine.pointAnchor(this.solarPoint9.nativeElement, {
      x: '50%',
      y: '60%'
    }),
      LeaderLine.pointAnchor(this.gridPointR.nativeElement, {
        x: '55%',
        y: 0
      }), { path: 'fluid', color: '#48CC93b5', dash: { animation: (this._animation && this.SolarWidget9.value) } });

    this.solarLine10 = new LeaderLine(LeaderLine.pointAnchor(this.solarPoint10.nativeElement, {
      x: '50%',
      y: '60%'
    }),
      LeaderLine.pointAnchor(this.gridPointR.nativeElement, {
        x: '65%',
        y: 0
      }), { path: 'fluid', color: '#48CC93b5', dash: { animation: (this._animation && this.SolarWidget10.value) } });


    /*** Home Usage Line ***/
    this.gridLine1 = new LeaderLine(
      LeaderLine.pointAnchor(this.gridPointD.nativeElement, {
        x: 0,
        y: '24%'
      }),
      LeaderLine.pointAnchor(this.homePoint1.nativeElement, {
        x: '100%',
        y: '35%'
      }), { path: 'fluid', color: '#AA000066', dash: { animation: (this._animation && this.HomeWidget1.value) } });

    this.gridLine2 = new LeaderLine(
      LeaderLine.pointAnchor(this.gridPointD.nativeElement, {
        x: 0,
        y: '32%'
      }),
      LeaderLine.pointAnchor(this.homePoint2.nativeElement, {
        x: '100%',
        y: '35%'
      }), { path: 'fluid', color: '#AA000066', dash: { animation: (this._animation && this.HomeWidget2.value) } });

    this.gridLine3 = new LeaderLine(
      LeaderLine.pointAnchor(this.gridPointD.nativeElement, {
        x: 0,
        y: '40%'
      }),
      LeaderLine.pointAnchor(this.homePoint3.nativeElement, {
        x: '100%',
        y: '35%'
      }), { path: 'fluid', color: '#AA000066', dash: { animation: (this._animation && this.HomeWidget3.value) } });

    this.gridLine4 = new LeaderLine(
      LeaderLine.pointAnchor(this.gridPointD.nativeElement, {
        x: 0,
        y: '48%'
      }),
      LeaderLine.pointAnchor(this.homePoint4.nativeElement, {
        x: '100%',
        y: '35%'
      }), { path: 'fluid', color: '#AA000066', dash: { animation: (this._animation && this.HomeWidget4.value) } });

    this.gridLine5 = new LeaderLine(
      LeaderLine.pointAnchor(this.gridPointD.nativeElement, {
        x: 0,
        y: '56%'
      }),
      LeaderLine.pointAnchor(this.homePoint5.nativeElement, {
        x: '100%',
        y: '35%'
      }), { path: 'fluid', color: '#AA000066', dash: { animation: (this._animation && this.HomeWidget5.value) } });



    this.gridLine6 = new LeaderLine(
      LeaderLine.pointAnchor(this.gridPointD.nativeElement, {
        x: '28%',
        y: 0
      }),
      LeaderLine.pointAnchor(this.homePoint6.nativeElement, {
        x: '50%',
        y: '60%'
      }), { path: 'fluid', color: '#AA000066', dash: { animation: (this._animation && this.HomeWidget6.value) } });

    this.gridLine7 = new LeaderLine(
      LeaderLine.pointAnchor(this.gridPointD.nativeElement, {
        x: '38%',
        y: 0
      }),
      LeaderLine.pointAnchor(this.homePoint7.nativeElement, {
        x: '50%',
        y: '60%'
      }), { path: 'fluid', color: '#AA000066', dash: { animation: (this._animation && this.HomeWidget7.value) } });

    this.gridLine8 = new LeaderLine(
      LeaderLine.pointAnchor(this.gridPointD.nativeElement, {
        x: '48%',
        y: 0
      }),
      LeaderLine.pointAnchor(this.homePoint8.nativeElement, {
        x: '50%',
        y: '60%'
      }), { path: 'fluid', color: '#AA000066', dash: { animation: (this._animation && this.HomeWidget8.value) } });

    this.gridLine9 = new LeaderLine(
      LeaderLine.pointAnchor(this.gridPointD.nativeElement, {
        x: '58%',
        y: 0
      }),
      LeaderLine.pointAnchor(this.homePoint9.nativeElement, {
        x: '50%',
        y: '60%'
      }), { path: 'fluid', color: '#AA000066', dash: { animation: (this._animation && this.HomeWidget9.value) } });

    this.gridLine10 = new LeaderLine(
      LeaderLine.pointAnchor(this.gridPointD.nativeElement, {
        x: '68%',
        y: 0
      }),
      LeaderLine.pointAnchor(this.homePoint10.nativeElement, {
        x: '50%',
        y: '60%'
      }), { path: 'fluid', color: '#AA000066', dash: { animation: (this._animation && this.HomeWidget10.value) } });


    /*** Grid to EV lines ***/
    this.evLine1 = new LeaderLine(LeaderLine.pointAnchor(this.gridPointD.nativeElement, {
      x: '100%',
      y: '20%'
    }),
      LeaderLine.pointAnchor(this.evPoint1.nativeElement, {
        x: 0,
        y: '35%'
      }), { path: 'fluid', color: '#AA000066', dash: { animation: (this._animation && this.EvWidget1.value) } });

    this.evLine2 = new LeaderLine(LeaderLine.pointAnchor(this.gridPointD.nativeElement, {
      x: '100%',
      y: '28%'
    }),
      LeaderLine.pointAnchor(this.evPoint2.nativeElement, {
        x: 0,
        y: '35%'
      }), { path: 'fluid', color: '#AA000066', dash: { animation: (this._animation && this.EvWidget2.value) } });

    this.evLine3 = new LeaderLine(LeaderLine.pointAnchor(this.gridPointD.nativeElement, {
      x: '100%',
      y: '36%'
    }),
      LeaderLine.pointAnchor(this.evPoint3.nativeElement, {
        x: 0,
        y: '35%'
      }), { path: 'fluid', color: '#AA000066', dash: { animation: (this._animation && this.EvWidget3.value) } });

    this.evLine4 = new LeaderLine(LeaderLine.pointAnchor(this.gridPointD.nativeElement, {
      x: '100%',
      y: '44%'
    }),
      LeaderLine.pointAnchor(this.evPoint4.nativeElement, {
        x: 0,
        y: '35%'
      }), { path: 'fluid', color: '#AA000066', dash: { animation: (this._animation && this.EvWidget4.value) } });

    this.evLine5 = new LeaderLine(LeaderLine.pointAnchor(this.gridPointD.nativeElement, {
      x: '100%',
      y: '52%'
    }),
      LeaderLine.pointAnchor(this.evPoint5.nativeElement, {
        x: 0,
        y: '35%'
      }), { path: 'fluid', color: '#AA000066', dash: { animation: (this._animation && this.EvWidget5.value) } });


    /*** DER Bank to Grid ***/
    this.solarGridLine = new LeaderLine(LeaderLine.pointAnchor(this.gridPointR.nativeElement, {
      x: '100%',
      y: '75%'
    }),
      LeaderLine.pointAnchor(this.gridPointD.nativeElement, {
        x: 0,
        y: '75%'
      }), { path: 'fluid', color: '#48CC93', startPlugColor: '#a6f41d', gradient: true,
        dash: { animation: (this._animation && (this.GridWidgetR.value > 0)) } });

    /* this.gridLine = new LeaderLine(LeaderLine.pointAnchor(this.gridPointR.nativeElement, {
      x: '100%',
      y: '35%'
    }),
      LeaderLine.pointAnchor(this.homePoint.nativeElement, {
        x: 0,
        y: '42%'
      }), {
      path: 'grid', color: '#AA0000', dash: { animation: (this.animation && !noGridConsumtion) },
      startPlug: (this.widgetData.gridSource != null && this.widgetData.gridSource < 0) ? 'arrow1' : 'behind',
      endPlug: (this.widgetData.gridSource == null || this.widgetData.gridSource >= 0) ? 'arrow1' : 'behind'
    }); */
  }

  private removeGridLines(): void {
    if (this.solarLine1) {
      this.solarLine1.remove();
    }
    if (this.solarLine2) {
      this.solarLine2.remove();
    }
    if (this.solarLine3) {
      this.solarLine3.remove();
    }
    if (this.solarLine4) {
      this.solarLine4.remove();
    }
    if (this.solarLine5) {
      this.solarLine5.remove();
    }
    if (this.solarLine6) {
      this.solarLine6.remove();
    }
    if (this.solarLine7) {
      this.solarLine7.remove();
    }
    if (this.solarLine8) {
      this.solarLine8.remove();
    }
    if (this.solarLine9) {
      this.solarLine9.remove();
    }
    if (this.solarLine10) {
      this.solarLine10.remove();
    }

    if (this.gridLine1) {
      this.gridLine1.remove();
    }
    if (this.gridLine2) {
      this.gridLine2.remove();
    }
    if (this.gridLine3) {
      this.gridLine3.remove();
    }
    if (this.gridLine4) {
      this.gridLine4.remove();
    }
    if (this.gridLine5) {
      this.gridLine5.remove();
    }
    if (this.gridLine6) {
      this.gridLine6.remove();
    }
    if (this.gridLine7) {
      this.gridLine7.remove();
    }
    if (this.gridLine8) {
      this.gridLine8.remove();
    }
    if (this.gridLine9) {
      this.gridLine9.remove();
    }
    if (this.gridLine10) {
      this.gridLine10.remove();
    }

    if (this.evLine1) {
      this.evLine1.remove();
    }
    if (this.evLine2) {
      this.evLine2.remove();
    }
    if (this.evLine3) {
      this.evLine3.remove();
    }
    if (this.evLine4) {
      this.evLine4.remove();
    }
    if (this.evLine5) {
      this.evLine5.remove();
    }

    if (this.solarGridLine) {
      this.solarGridLine.remove();
    }
  }

  ngOnDestroy(): void {
    this.removeGridLines();
  }

}
