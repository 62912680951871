import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MenuService } from './framework/shared/service/menu.service';
import { Router, Event, NavigationStart, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { LRObject } from './commonOptions';
import { MediaMatcher } from '@angular/cdk/layout';
import { UserSessionService } from './framework/shared/service/user-session.service';
import { environment } from 'src/environments/environment';
declare var LeaderLine: any;
declare function LoginRadiusV2(commonOptions): void;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  title = 'PFC';
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  loginEndPoint: string;

  constructor(private router: Router, private menuService: MenuService, media: MediaMatcher, private uSession: UserSessionService,
              changeDetectorRef: ChangeDetectorRef) {
    this.loginEndPoint =  environment.globalIamEndPoint;
    this.mobileQuery = media.matchMedia('(max-width: 959px)');
    this._mobileQueryListener = () => {
      uSession.mobileMode = this.mobileQuery.matches;
      changeDetectorRef.detectChanges();
    };
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        // do something on start activity
      }

      if (event instanceof NavigationEnd) {
        // do something on end activity
        if (this.router.url !== '/home' && this.router.url !== '/login') {
          this.menuService.loadApplicationMenu(this.router.url);
          console.log('NavigationEnd aRoute:::', this.router.url);
        }
      }
    });
  }

  ngOnInit(): void {
    this.uSession.mobileMode = this.mobileQuery.matches;
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
}
