export const message = {
    
    loadLiveMessage: 'Fetching requested data...',
    meterDataInfoMessage: 'Meter data for this period is not yet available',
    liveStreamServerError: 'Live stream ended due to server error',
    accountNotFound: 'You don\'t have any account id associated with',
    dataNotAvailable: 'Data is not available for the selected period',
    liveStreamNotReceived: 'Not recieved live data, trying again...',
    noSiteAccessMessage: 'You do not have access to view this site. Please contact <a href=\"mailto:L2Lpilot@londonhydro.com\">L2Lpilot@londonhydro.com</a> to request access.',
    noLocationFound: 'No location found',
    noDataFound: 'No Data Found'
    
  };