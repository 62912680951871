import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as moment from 'moment-timezone';
import { UIUtil, SummaryView } from '@londonhydro/ui-framework-lib/ui-util-lib';

@Injectable({
  providedIn: 'root'
})
export class SolarAssetService {

  constructor(private http: HttpClient) { }


  getSolarAssetList(): Observable<any> {
    const jsonEndPoint = `/derprofile/smartHomeProfiles`;
    return this.http.get<any>(environment.derMsEndPoint + jsonEndPoint)
      .pipe(
        tap(res => { console.log('fetched solar asset details', res); }),
        catchError(this.handleError('SADetails', null))
      );
  }

  getSolarAssetViewList(startDate: Date, endDate: Date, summaryView: string, assetType: string, ldcId: string, programName: string): Observable<any> {
     const iStartDate = moment.tz(startDate, UIUtil.UiTimeZone).format(UIUtil.UiDateApiInputFormat);
     const iEndDate = moment.tz(endDate, UIUtil.UiTimeZone).format(UIUtil.UiDateApiInputFormat);
     const period: string =  summaryView || SummaryView.DAILY;

     const jsonEndPoint = `/derprofile/allaccounts?startDate=${iStartDate}&endDate=${iEndDate}&period=${period}&assetType=${assetType}&ldcId=${ldcId}&programName=${programName}`;
     return this.http.get<any>(environment.derMsEndPoint + jsonEndPoint)
      .pipe(
        tap(res => { console.log('fetched solar asset view details', res); }),
        catchError(this.handleError('SAVIEWDetails', null))
      );
  }

  private handleError<T>(operation = 'operation', result?: T): any {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      // TODO: better job of transforming error for user consumption
      console.error(`${operation} failed: ${error.message}`);
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
