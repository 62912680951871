<div *ngIf="selectedMenu" fxLayout="row" fxLayoutAlign="start center" class="p-2">
  <div class="p-2" *ngIf="showMenuOption">
    <button mat-icon-button (click)="toggleSideMenu()">
      <mat-icon class="menu-mobile">menu</mat-icon>
    </button>
  </div>
  <!-- <div mat-list-item fxLayout="column" fxLayoutAlign="center center" class="menu-item p-0">
    <div class="p-0">
      <div class="menu-icon circle1 disabled" [ngClass]="selectedMenu.iconClass"></div>
    </div>
  </div> -->
  <div fxFlex fxLayoutAlign="center center" class="mt-2 p-2">
  <p class="page-title-text">{{selectedMenu.label}} {{selectedMenu.role === 'DEVELOPER'? ' - (Development in Progress)' : ''}}</p>
  </div>
</div>
