import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'src/app/framework/shared/model/menuitems';
import { MatSelectChange } from '@angular/material/select';
import * as _ from 'underscore';
import { Subscription } from 'rxjs';
import { IUser } from 'src/app/framework/shared/model/iuser';
import { UserSessionService } from 'src/app/framework/shared/service/user-session.service';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss']
})
export class TopMenuComponent implements OnInit, OnDestroy {

  private masterMenuList: MenuItem[];
  userMenuList: MenuItem[];

  selectedMenu: MenuItem;
  userSubscription: Subscription;
  loggedUser: IUser;
  currentTabIndex: number;

  @Input()
  set menuItems(menuItems: MenuItem[]) {
    if (this.masterMenuList !== menuItems) {
      this.masterMenuList = menuItems;
      this.loadUserMenu();
    }
  }

  constructor(private router: Router, private userSessionService: UserSessionService) { }

  ngOnInit(): void {
    this.userSubscription = this.userSessionService.loggedUser.subscribe(user => {
      if (user) {
        user.customFields = user.customFields || {};
        if (user !== this.loggedUser) {
          console.log('change in user detected..loading data ...');
          this.loggedUser = _.extend({}, user);
          this.loadUserMenu();
        }
      }
    });
  }

  private loadUserMenu(): void {
    if (this.masterMenuList && this.loggedUser) {
      let userRoles = this.loggedUser.roles;
      if (!userRoles || _.isEmpty(userRoles)) {
        userRoles = ['USER'];
      }
      this.userMenuList = _.filter(this.masterMenuList, (menu: MenuItem) =>
        menu.role === null || (menu.role && _.contains(userRoles, menu.role)));

      if (this.selectedMenu === null || _.isUndefined(this.selectedMenu)) {
        if (this.userMenuList && !_.isEmpty(this.userMenuList)) {
          const lthis = this;
          let sMenu = _.find(this.userMenuList, (menu: MenuItem) => {
            return lthis.router.url === menu.action;
          });
          if (!sMenu) {
            sMenu = _.find(this.userMenuList, (menu: MenuItem) => {
              return lthis.router.url.startsWith(menu.action);
            });
          }
          // console.log('inside top menu:::' + lthis.router.url, sMenu);
          // this.navigateToMenu(sMenu || this.userMenuList[0]);
          if (sMenu) {
            this.navigateToMenu(sMenu, true);
          }
        }
      }
    }
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  navigateToMenu(menu: MenuItem, pageRefresh: boolean = false): void {
    console.log(' TopMenuComponent : this.selectedMenu :', menu);
    if (menu !== this.selectedMenu) {
      this.selectedMenu = menu;
      this.currentTabIndex = this.userMenuList.indexOf(this.selectedMenu);
      if (!pageRefresh || (this.router.url === this.selectedMenu.action)) {
        this.router.navigate([this.selectedMenu.action]);
      }
    } else {
      console.log('User is trying to load same menu...');
    }
  }

  tabChangeHandler(event: MatTabChangeEvent): void {
    console.log(event.index + ':TopMenuComponent::::tabChangeHandler::::', event);
    this.navigateToMenu(this.userMenuList[event.index]);
  }

  optionChangeHandler(event: MatSelectChange): void {
    console.log('optionChangeHandler::::', event);
    this.selectedMenu = event.value;
    this.router.navigate([this.selectedMenu.action]);
  }
}
