<div fxFill class="side-bar seperator-x {{showNav?'open':''}} {{mobileClass}}"
  [ngStyle]="mobileView?null:{'width': '90px'}">
  <mat-sidenav-container class="h-100 sidemenu-container {{mobileClass}}">
    <mat-sidenav class="w-100 side-menu" #snav [(opened)]="showNav" [mode]="openMode">
      <mat-nav-list class="pt-0">
        <div mat-list-item fxLayout="column" fxLayoutAlign="space-around center"
          class="menu-item p-0 py-2 {{menu.name===selectedMenu.name?'active':''}}" *ngFor="let menu of userMenuList"
          [ngClass]="menu.name===selectedMenu.name?'active':''" (click)="navigateToMenu(menu)" [matTooltip]="menu.title"
          [matTooltipPosition]="'after'">
          <div class="p-0 menu-sprite menu-icon-base">
            <div class="menu-icon menu-sprite {{menu.name===selectedMenu.name?'active':''}}" [ngClass]="menu.iconClass"></div>
          </div>
          <p class="font-weight-bold text-secondary mb-0">{{menu.label}}</p>
        </div>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <ng-container>
        <ng-content></ng-content>
      </ng-container>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
