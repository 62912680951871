import { Component, OnInit, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { MenuService } from 'src/app/framework/shared/service/menu.service';
import { MenuItem } from 'src/app/framework/shared/model/menuitems';
import { Subscription } from 'rxjs';
import { UserSessionService } from 'src/app/framework/shared/service/user-session.service';

@Component({
  selector: 'app-base-layout',
  templateUrl: './base-layout.component.html',
  styleUrls: ['./base-layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BaseLayoutComponent implements OnInit, OnDestroy {

  @Input() showSideMenu = false;
  @Input() showCustomContent = false;
  @Input() topMenu: MenuItem[];

  mobileMode = false;
  menuMode = 'side';
  toogleMenuView = false;

  sideMenuList: MenuItem[];
  menuLoad: Subscription;

  constructor(private menuService: MenuService, private uSession: UserSessionService) {
  }

  toogleMenu(event: any): void {
    console.log('toogleMenu:::', event);
    this.toogleMenuView = event;
  }

  ngOnInit(): void {
    this.menuLoad = this.menuService.appMenuList.subscribe(sMenuList => {
      if (sMenuList && this.sideMenuList !== sMenuList) {
        this.sideMenuList = sMenuList;
        // console.log('this.sideMenuList:::', this.sideMenuList);
      }
    });
    // this.toogleMenuView = !this.mobileQuery.matches;
    this.mobileMode = this.uSession.mobileMode;
    this.menuMode = this.mobileMode ? 'over' : 'side';
    this.toogleMenuView = !this.uSession.mobileMode;
  }

  ngOnDestroy(): void {
    if (this.menuLoad) {
      this.menuLoad.unsubscribe();
    }
  }
}
