import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import * as _ from 'underscore';
import { DecimalPipe, LocationStrategy } from '@angular/common';
import { Subscription, timer, interval } from 'rxjs';
import { IUser } from 'src/app/framework/shared/model/iuser';
import { UserSessionService } from 'src/app/framework/shared/service/user-session.service';
import { User } from 'src/app/modules/auth/model/user';
import { Router } from '@angular/router';
import { UIUtil, DatePeriod, SummaryView } from '@londonhydro/ui-framework-lib/ui-util-lib';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import * as moment from 'moment-timezone';
import { SummaryViews } from 'src/app/framework/ui-lib/model/summaryviews';
import { SolarAssetService } from '../../service/solarasset.service';
import { MatSort } from '@angular/material/sort';
import { message } from 'src/app/framework/ui-lib/messages/message';

@Component({
  selector: 'app-newsolarasset',
  templateUrl: './newsolarasset.component.html',
  styleUrls: ['./newsolarasset.component.scss']
})
export class NewsolarassetComponent implements OnInit {

  dataLoadPending = false;
  notLiveRecord = false;
  masterAssetList: Array<any>;
  @ViewChild(MatPaginator) paginatorLive: MatPaginator;
  @ViewChild(MatSort) sortLive: MatSort;
  @ViewChild(MatPaginator) paginatorOthers: MatPaginator;
  @ViewChild(MatSort) sortOthers: MatSort;
  userMessage: string;
  userLiveMessage: string;
  errorState = 'info';
  subscription: Subscription;
  dataSource: MatTableDataSource<any>;
  TODAY_DATE = moment.tz(UIUtil.UiTimeZone).startOf('day').toDate();
  displayedColumns: string[] = ['index', 'accountId', 'generation', 'netZero', 'solarContribution', 'solar', 'grid', 'homeUsage', 'currentPerformance', 'grossGeneration', 'expectedGeneration',
    'consumptionCost', 'solarSavings', 'totalEnergyCost', 'source', 'updated'];

  dataViewSource: MatTableDataSource<any>;
  displayedViewColumns: string[] = ['index', 'accountId', 'generation', 'netZero', 'solarContribution', 'solar', 'grid', 'homeUsage', 'netGeneration', 'netConsumption', 'currentPerformance', 'grossGeneration', 'expectedGeneration',
    'consumptionCost', 'solarSavings', 'totalEnergyCost', 'source', 'updated'];

  loggedUser: IUser;
  userSubscription: Subscription;
  /** Added for date range */
  uiTimeZone = UIUtil.UiTimeZone;
  MAX_DATE: Date;
  MIN_DATE: Date;
  periodDateFormat = 'MMM dd, yyyy';
  dateForm: FormGroup;
  viewPeriod: DatePeriod = new DatePeriod();
  disablePreviousPeriod = true;
  disableNextPeriod = true;
  accessProhibited: boolean;
  expectedGenerationForToday: number;
  generationToday: number;
  maxStartTime: Date;
  historyCount = 0;
  maxHistoryCheck = 3;
  firstDataLoad = true;

  summaryViews = [];
  summaryView: string;
  currentViewIndex = 0;

  communityName = 'LH';

  currentView: string;

  liveStreamOn: string;
  lastUpdatedOn: Date;
  maxRetryCount = 24;
  retryCount: number;
  liveDataSubscription: Subscription;
  imageArrRow = [];
  smallScreen = false;

  constructor(private solarAssetsService: SolarAssetService, private decimalPipe: DecimalPipe,
      private locationStrategy: LocationStrategy, private userSessionService: UserSessionService,
      private router: Router, private fb: FormBuilder) {
    history.pushState(null, null, location.href);
    this.locationStrategy.onPopState(() => {
      history.pushState(null, null, location.href);
    })
    this.dateForm = this.fb.group({
        dateSelect: ''
      });
  }

  ngOnInit(): void {
    this.smallScreen = this.userSessionService.mobileMode;
    this.userSubscription = this.userSessionService.loggedUser.subscribe(user => {
      if (user) {
        user.customFields = user.customFields || {};
        if ((user !== this.loggedUser) || (this.loggedUser && (this.loggedUser.customFields.accountId !== user.customFields.accountId))) {
          console.log('change in user object detected..loading data ...');
          // this.loggedUser = _.extend({}, user);
          this.loggedUser = user;
          this.communityName = this.loggedUser.customFields.LDC || 'LH';
        }
      }
    });
    this.loadInitialData();
  }

  private loadInitialData(): void {
    this.resetData();
    this.getSolarAssetLiveList();
  }

  private resetData(): void {
    this.resetSummaryViews(null);
    this.firstDataLoad = true;
    this.dataLoadPending = false;
    this.updateDateBoundary();
    this.viewPeriod = DatePeriod.getDefaultPeriod(this.summaryView, this.uiTimeZone);
    this.validatePeriodRange();
  }

  refreshEvent(): void {
    console.log('refresh event: ');
    this.getSolarAssetLiveList();
  }

  compareDate(presentDate: any, updatedDate: Date): boolean {
    if (moment(updatedDate).format('YYYY-MM-DD') < moment(presentDate).format('YYYY-MM-DD')) {
      this.notLiveRecord = true;
    }else{
      this.notLiveRecord = false;
    }
    return this.notLiveRecord;
  }


   private validatePeriodRange(): void {
    if (this.viewPeriod.endDate >= this.MAX_DATE) {
      this.disableNextPeriod = true;
    } else {
      this.disableNextPeriod = false;
    }
    if (this.viewPeriod.startDate <= this.MIN_DATE) {
      this.disablePreviousPeriod = true;
    } else {
      this.disablePreviousPeriod = false;
    }
  }

  updateDateBoundary(): void {
    this.MAX_DATE = moment.tz(this.uiTimeZone).startOf('day').subtract(1, 'day').toDate();
    this.MIN_DATE = moment.tz(this.uiTimeZone).startOf('year').subtract(2, 'year').toDate();
  }

  private resetSummaryViews(granularity: string): void {
    console.log('ResetSummaryViews granularity:' + granularity);
    this.summaryViews = SummaryViews.ALL_VIEWS_ASSET;
    if (granularity) {
      if (granularity.startsWith('Hour')) {
        granularity = SummaryView.DAILY;
      }
      const startIndex = _.indexOf(this.summaryViews, _.find(this.summaryViews, function(v: string) { return v.startsWith(granularity); }));
      this.summaryViews = _.rest(this.summaryViews, startIndex < 0 ? 0 : startIndex);
      console.log(_.indexOf(this.summaryViews, granularity) + '-this.summaryViews:::', this.summaryViews);
    }
    this.summaryView = this.summaryViews[0];
    this.currentViewIndex = _.indexOf(this.summaryViews, this.summaryView);
    // this.mySolarProfile = new MySolarAssets(this.summaryView);
  }


  changeSummaryView(event: any): void {

    this.masterAssetList = [];
    this.dataSource = null;
    this.dataViewSource = null;
    this.summaryView = this.summaryViews[event.index];
    // this.dataLoadPending = true;
    // this.resetMessage();

    /** Changes for maxStartTime starts here.. */
    if (this.summaryView === SummaryView.LIVE || this.summaryView === SummaryView.LIVE2) {
      this.viewPeriod = DatePeriod.getDefaultPeriod(this.summaryView, this.uiTimeZone);
    } else {
      if (this.maxStartTime && this.maxStartTime !== undefined) {
        this.viewPeriod = DatePeriod.getSelectedPeriod(this.maxStartTime, this.summaryView, this.uiTimeZone);
      } else {
        this.viewPeriod = DatePeriod.getDefaultPeriod(this.summaryView, this.uiTimeZone);
      }
    }
    this.periodDateFormat = this.summaryView === SummaryView.YEARLY ? 'yyyy' : (this.summaryView === SummaryView.MONTHLY ? 'MMMM, yyyy' : 'MMM dd, yyyy');
    this.validatePeriodRange();
    if (this.summaryView === SummaryView.LIVE || this.summaryView === SummaryView.LIVE2) {
      this.getSolarAssetLiveList();
    } else {
    this.getSolarAssetViewList();
    }
  }

  viewPreviousPeriod(): void {
    this.viewPeriod = DatePeriod.getPreviousPeriod(this.summaryView, this.viewPeriod, this.uiTimeZone);
    this.validatePeriodRange();
    this.getSolarAssetViewList();
  }

  viewNextPeriod(): void {
    this.viewPeriod = DatePeriod.getNextPeriod(this.summaryView, this.viewPeriod, this.uiTimeZone);
    this.validatePeriodRange();
    this.getSolarAssetViewList();
  }

  viewPeriodChangeHandler(normalizedDate?: moment.Moment, datepicker?: MatDatepicker<moment.Moment>): void {
    console.log('normalizedMonth::', normalizedDate);
    let selectedDate = null;
    if (normalizedDate) {
      selectedDate = moment.tz(normalizedDate, this.uiTimeZone).toDate();
      datepicker.close();
    } else {
      selectedDate = moment.tz(this.dateForm.controls.dateSelect.value, this.uiTimeZone).toDate();
    }
    if (selectedDate !== null) {
      this.viewPeriod = DatePeriod.getSelectedPeriod(selectedDate, this.summaryView, this.uiTimeZone);
      this.validatePeriodRange();
      this.getSolarAssetViewList();
    }
  }

 // Code for Solar asset starts here..

  private getSolarAssetLiveList(): void {
    this.dataLoadPending = true;
    this.masterAssetList = [];

    this.solarAssetsService.getSolarAssetList().subscribe(jsonData => {

      this.masterAssetList = jsonData;
      const cthis = this;
      let homeUsage: number;
      let solarGen: number;
      let consumptionCost: number;
      let solarSavings: number;
      let totalEnergyCost: number;
      let performance: number;
      let co2offset: number;
      let generation: number;
      let netHours: number;
      let solarContributors = [];

      console.log('Display user data: ', this.masterAssetList);

      if (this.masterAssetList && !_.isEmpty(this.masterAssetList)) {
        this.userLiveMessage = null;
        this.errorState = 'info';
        const lthis = this;

        const filteredList = _.filter(this.masterAssetList, function (ap) {
          if (ap.energyProfile !== undefined){
            homeUsage = +cthis.decimalPipe.transform(ap.energyProfile.live.consumption, '1.0-2').replace(/,/g, '');
            solarGen = +cthis.decimalPipe.transform(ap.energyProfile.live.generation, '1.0-2').replace(/,/g, '');
            consumptionCost = +cthis.decimalPipe.transform(ap.energyProfile.live.energyCost, '1.0-2').replace(/,/g, '');
            solarSavings = +cthis.decimalPipe.transform(ap.energyProfile.live.generationSavings, '1.0-2').replace(/,/g, '');
            totalEnergyCost = +cthis.decimalPipe.transform(ap.energyProfile.live.energyCost - ap.energyProfile.live.generationSavings, '1.2-2').replace(/,/g, '');
            solarContributors = ap.energyProfile.contributors.filter((x) => x.includes('solar'));

            ap.energyProfile.live.consumption = homeUsage;
            ap.energyProfile.live.generation = solarGen;
            ap.energyProfile.live.energyCost = consumptionCost;
            ap.energyProfile.live.generationSavings = solarSavings;
            ap.energyProfile.contributors = solarContributors;
            ap.solarPercentage = (ap.energyProfile.generationProfile && ap.energyProfile.generationProfile.SOLAR && 
              ap.energyProfile.generationProfile.SOLAR.live) ? ((ap.energyProfile.generationProfile.SOLAR.live.mix || 0) * 100) : null;
            //ap.solarPercentage = (ap.energyProfile.generationProfile.SOLAR.live.mix || 0) * 100;
            ap.netCost = (ap.energyProfile.live.energyCost === null || ap.energyProfile.live.generationSavings === null) ? 0 : ap.energyProfile.live.energyCost - ap.energyProfile.live.generationSavings;
            return (ap.energyProfile.generationProfile !== undefined && ap.energyProfile.generationProfile.SOLAR !== undefined && ap.energyProfile.generationProfile.SOLAR.profile !== undefined);
          }

        });
        console.log('masterAssetList List:::', filteredList);

        const finalList = filteredList.filter(x => x.energyProfile.contributors.length !== 0);

        this.dataSource = new MatTableDataSource(finalList);
        this.dataSource.paginator = this.paginatorLive;
        this.dataSource.sort = this.sortLive;
        this.dataLoadPending = false;
      } else {
        this.userLiveMessage = message.noDataFound;
        this.errorState = 'warning';
      }
      this.dataLoadPending = false;

    });
  }

  private getSolarAssetViewList(datePeriod?: DatePeriod): void {
    this.dataLoadPending = true;
    this.masterAssetList = [];
    this.dataViewSource = null;
    const assetType = 'SOLAR';
    const programName = 'L2L'

    let homeUsage: number;
    let solarGen: number;
    let consumptionCost: number;
    let solarSavings: number;
    let totalEnergyCost: number;
    datePeriod = datePeriod || this.viewPeriod;
    const cthis = this;

    this.solarAssetsService.getSolarAssetViewList(datePeriod.startDate, datePeriod.endDate, this.summaryView, assetType, this.communityName, programName).subscribe(jsonData => {

      this.masterAssetList = jsonData;

      console.log('Display user data: ', this.masterAssetList);

      if (this.masterAssetList && !_.isEmpty(this.masterAssetList)) {
        this.userMessage = null;
        this.errorState = 'info';
        const lthis = this;

        const filteredList = _.filter(this.masterAssetList, function (ap) {
          const assetProfile = _.find(ap.energyMix, eobj => eobj.assetType === assetType) || {};
          ap.energyMix = assetProfile;

          homeUsage = ap.energyMix.consumption;
          solarGen = ap.energyMix.generation;
          consumptionCost = ap.energyCost;
          solarSavings = ap.energyMix.generationSavings;
          totalEnergyCost = ap.energyMix.energyCost - ap.energyMix.generationSavings;

          console.log('ap.consumption: ', ap.consumption);
          console.log('ap.energyMix.generation: ', ap.energyMix.generation);

          ap.gridVal = ap.consumption !== null ? Math.max((ap.consumption || 0) - (ap.energyMix.generation || 0), 0) : 0;
          ap.netCost = (ap.energyCost === null || ap.generationSavings === null) ? 0 : ap.energyCost - ap.generationSavings;
          ap.energyMix.consumption = homeUsage;
          ap.energyMix.generation = solarGen;
          ap.energyCost = consumptionCost;
          ap.energyMix.generationSavings = solarSavings;
          ap.solarPercentage = (assetProfile.mix || 0) * 100;

          return ap;
          // return (ap.energyProfile.generationProfile !== undefined && ap.energyProfile.generationProfile.SOLAR !== undefined && ap.energyProfile.generationProfile.SOLAR.profile !== undefined);
        });

        console.log('masterAssetList solar asset List = ', filteredList);

        this.dataViewSource = new MatTableDataSource(filteredList);
        setTimeout(() => {
        this.dataViewSource.paginator = this.paginatorOthers;
        }, 300);
        this.dataViewSource.sort = this.sortOthers;
        this.dataLoadPending = false;
      } else {
        this.userMessage = message.noDataFound;
        this.errorState = 'warning';
      }
      this.dataLoadPending = false;

    });
  }

  navigateToDashboard(account: any): void {
    console.log('onAccountIdChange account id...', account);
    if (this.loggedUser) {
      this.loggedUser.customFields.accountId = account.accountId;
      this.userSessionService.setLoggedUser(this.loggedUser);
      this.router.navigate(['/asset']);
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
