import { IUser } from 'src/app/framework/shared/model/iuser';
import { Role } from './role';
import * as _ from 'underscore';

export class User implements IUser {
  accountId: string;
  accountList: Array<string>;
  accountNonExpired: boolean;
  accountNonLocked: boolean;
  authorities: Role[];
  credentialsNonExpired: boolean;
  customFields: any;
  emailList: Array<any>;
  enabled: boolean;
  firstName: string;
  fullName: string;
  id: string;
  isActive: boolean;
  isDeleted: boolean;
  isLocked: boolean;
  language: string;
  lastLoginDate: Date;
  lastName: string;
  middleName: string;
  roles: Array<string>;
  roleContextParam: any;
  status: string;
  uid: string;
  username: string;

  get emailId(): string {
    if (this.emailList && !_.isEmpty(this.emailList)) {
      return this.emailList[0].Value;
    }
    return null;
  }

  get hasAdminRole(): boolean {
    return _.contains(this.roles, 'ADMIN');
  }

  get hasAnyRole(): boolean {
    const r = _.find(this.roles, (role: string) => {
      return role === 'ADMIN' || role === 'OWNER' || role === 'DELEGATE' || role === 'PUBLIC';
    });
    return (r && r !== null);
  }

  hasRole(role: string): boolean {
    return _.contains(this.roles, role);
  }
}
