import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import * as moment from 'moment-timezone';
import { UIUtil } from '@londonhydro/ui-framework-lib/ui-util-lib';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  private consentCookieKey = 'pfc-cookie-consent';
  showCookieInfo = true;

  constructor(private cookieService: CookieService) { }

  ngOnInit(): void {
    if (this.cookieService.check(this.consentCookieKey)) {
      if (this.cookieService.get(this.consentCookieKey) === 'Y') {
        this.showCookieInfo = false;
      }
    }
  }

  hideCookieInfo(): void {
    this.cookieService.set(this.consentCookieKey, 'Y', moment.tz(UIUtil.UiTimeZone).add(1, 'day').toDate(), '/');
    this.showCookieInfo = false;
  }

}
