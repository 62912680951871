import { AppType } from './apptype';
import { UIElement } from './uielement';
import { GBCertification } from './gbcertification';
import * as _ from 'underscore';

export class Application {
    name: string;
    appLogoUrl: string;
    appLogoClass: any;
    types: Array<string>;
    categories: Array<string>;
    appTypes: AppType[];
    bodyTextContent: string;
    gbCertification: GBCertification;
    footerElements: UIElement[];

    get iOSApp(): AppType {
        if (this.appTypes && !_.isEmpty(this.appTypes)) {
            return _.find(this.appTypes, (app: AppType) => app.type === 'ios');
        }
        return null;
    }

    get androidApp(): AppType {
        if (this.appTypes && !_.isEmpty(this.appTypes)) {
            return _.find(this.appTypes, (app: AppType) => app.type === 'android');
        }
        return null;
    }

    get webApp(): AppType {
        if (this.appTypes && !_.isEmpty(this.appTypes)) {
            return _.find(this.appTypes, (app: AppType) => app.type === 'web');
        }
        return null;
    }

    get hasIOSApp(): boolean {
        if (this.appTypes && !_.isEmpty(this.appTypes)) {
            const app = _.find(this.appTypes, (app: AppType) => app.type === 'ios');
            return (app && app !== null);
        }
        return false;
    }

    get hasAndroidApp(): boolean {
        if (this.appTypes && !_.isEmpty(this.appTypes)) {
            const app = _.find(this.appTypes, (app: AppType) => app.type === 'android');
            return (app && app !== null);
        }
        return false;
    }

    get hasWebApp(): boolean {
        if (this.appTypes && !_.isEmpty(this.appTypes)) {
            const app = _.find(this.appTypes, (app: AppType) => app.type === 'web');
            return (app && app !== null);
        }
        return false;
    }

    get isThirdPartyApp(): boolean {
        if (this.categories && !_.isEmpty(this.categories)) {
            const app = _.find(this.categories, (app: string) => app === 'thirdPartyApp');
            return (app && app !== null);
        }
        return false;
    }

    get isGBPlatformApp(): boolean {
        if (this.categories && !_.isEmpty(this.categories)) {
            const app = _.find(this.categories, (app: string) => app === 'gbPlatform');
            return (app && app !== null);
        }
        return false;
    }
}
