import { NgModule, ModuleWithProviders, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuService } from './service/menu.service';
import { UserSessionService } from './service/user-session.service';
import { HttpIntercepterService } from './service/http-intercepter.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CustomerService } from './service/customer.service';
import { RouteGuard } from './guards/route-guard.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  exports: [CommonModule]
})
export class SharedModule {
  /* constructor(@Optional() @SkipSelf() parentModule?: SharedModule) {
    if (parentModule) {
      throw new Error('SharedModule is already loaded. Import it in the AppModule only');
    }
  } */
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [MenuService, UserSessionService, CustomerService, RouteGuard,
        { provide: HTTP_INTERCEPTORS, useClass: HttpIntercepterService, multi: true }]
    };
  }
}
