<div fxLayout="row" fxLayoutAlign="start center" class="top-menu" fxShow fxHide.lt-md>
  <mat-tab-group fxFlex="100" class="tab-menu-group" (selectedTabChange)="tabChangeHandler($event)"
    [selectedIndex]="currentTabIndex">
    <mat-tab *ngFor="let menu of userMenuList;let index = index; let last = last;" [disabled]="!menu.enabled">
      <ng-template mat-tab-label>
        <span mat-icon-button [matTooltip]="menu.title" [innerHTML]="menu.label"></span>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
<div fxLayout="column" fxLayoutAlign="start stretch" class="top-menu-list" [ngClass.gt-md]="'px-3'" fxHide fxShow.lt-md>
  <mat-form-field class="px-1" appearance="fill">
    <mat-label>Navigate to</mat-label>
    <mat-select class="c-select" [(value)]="selectedMenu" (selectionChange)="optionChangeHandler($event)">
      <mat-option *ngFor="let menu of userMenuList; let i = index" [value]="menu" [disabled]="!menu.enabled">
        <span [innerHTML]="menu.label"></span>
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>