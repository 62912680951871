<app-page-loader *ngIf="dataLoadPending" class="app-loader"></app-page-loader>

<div fxFill fxLayout="row wrap" fxLayoutAlign="space-between start">
	<!-- <div fxFlex="100" fxLayout="row wrap" class="tile-card">
		<div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-between start" class="tile-card-header">
			<h1 fxFlex.lt-md="100"></h1>
			<div fxFlex.lt-md="100" *ngIf="summaryView !== 'Live'" fxLayout="row" fxLayoutAlign="end center">
				<button mat-mini-fab class="blue-button left rounded-button mr-2" (click)="viewPreviousPeriod()"
					[disabled]="disablePreviousPeriod">
					<i class="ace-icon fa fa-chevron-left ace-icon-align"
						matTooltip="{{disablePreviousPeriod? 'You are viewing the first period of available data' : 'View previous period data'}}"></i>
				</button>
				<div style="font-weight: bold;" *ngIf="viewPeriod">
					<span style="font-size: 18px">{{viewPeriod.startDate| date : periodDateFormat: uiTimeZone}}</span>
					<span style="font-size: 18px" *ngIf="summaryView === 'Weekly'"> -
						{{viewPeriod.endDate| date : periodDateFormat: uiTimeZone}}</span>
					<mat-form-field [formGroup]="dateForm" class="date-picker-custom" *ngIf="summaryView !== 'Live'">
						<input matInput type="text" [matDatepicker]="picker" (dateChange)="viewPeriodChangeHandler()"
							formControlName="dateSelect" [min]="MIN_DATE" [max]="MAX_DATE" style="display: none;">
						<mat-datepicker-toggle matSuffix [for]="picker" class="btn-date-picker"></mat-datepicker-toggle>
						<mat-datepicker touchUi="{{smallScreen}}" #picker
							startView="{{summaryView === 'Yearly'? 'multi-year' : (summaryView === 'Monthly'?'year':'month')}}"
							[startAt]="viewPeriod.startDate" (yearSelected)="viewPeriodChangeHandler($event, picker)"
							(monthSelected)="viewPeriodChangeHandler($event, picker)"></mat-datepicker>
					</mat-form-field>
				</div>

				<button mat-mini-fab class="blue-button rounded-button ml-2" (click)="viewNextPeriod()"
					[disabled]="disableNextPeriod">
					<i class="ace-icon fa fa-chevron-right ace-icon-align"
						matTooltip="{{disableNextPeriod? 'You are viewing the last period of available data' : 'View next period data'}}"></i>
				</button>
			</div>
		</div>
		<div fxFlex="50" fxLayout="row" class="filter-view mb-3">
			<div class="text-nowrap">Filter by:</div>
			<mat-tab-group class="tab-menu-group toggle-group" (selectedTabChange)="changeSummaryView($event)"
				[selectedIndex]="currentViewIndex">
				<mat-tab *ngFor="let view of summaryViews;let index = index; let last = last;"
					[ngStyle.lt-sm]="{'min-width.px':'85'}" [disabled]="accountId == null || accessProhibited">
					<ng-template mat-tab-label>
						
						<span mat-icon-button
							[matTooltip]="view==='Live'?('Last updated on ' + (lastUpdatedOn | date :'medium')):''"
							style="font-weight: bold;">{{view}}</span>
					</ng-template>
				</mat-tab>
			</mat-tab-group>
		</div>
	</div> -->

	<div fxLayout="row" fxFlex="100" fxLayoutAlign="end">
		<button type="button" class="btn btn-link ml-2" (click)="refreshEvent()">Refresh </button>
	</div>


	<div fxFlex="100" fxLayout="row wrap" class="tile-card">
		<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

			<!-- Index Column -->
			<ng-container matColumnDef="index" sticky>
				<th mat-header-cell *matHeaderCellDef class="fontheader"> Index </th>
				<td mat-cell *matCellDef="let element; let i = index"> {{i + 1}} </td>
			</ng-container>

			<!-- Account Id Column -->
			<ng-container matColumnDef="accountId">
				<th mat-header-cell *matHeaderCellDef mat-sort-header class="fontheader"> Account Id </th>
				<!-- <td mat-cell *matCellDef="let element"> <button type="button" class="btn btn-link"
						(click)="navigateToDashboard(element)">{{element.accountId}} </button></td> -->
					<td mat-cell *matCellDef="let element" > {{element.accountId}} </td>
			</ng-container>

			<ng-container matColumnDef="device">
				<th mat-header-cell *matHeaderCellDef class="fontheader"> Device </th>
				<td mat-cell *matCellDef="let element"> <label mat-label
						*ngIf="element.thermostatProfile.current !== undefined">
						{{ element.thermostatProfile.current.device }}
					</label> </td>
				<!-- <td mat-cell *matCellDef="let element">  </td> -->
			</ng-container>

			<ng-container matColumnDef="comfortPreference">
				<th mat-header-cell *matHeaderCellDef class="fontheader"> Comfort Preference </th>
				<td mat-cell *matCellDef="let element"><label mat-label
						*ngIf="element.thermostatProfile.current">
						{{ element.thermostatProfile.current.comfortPreference }} </label> </td>
			</ng-container>

			<!-- Solar Column -->
			<ng-container matColumnDef="duration">
				<th mat-header-cell *matHeaderCellDef class="fontheader"> Duration (Hour) </th>
				<td mat-cell *matCellDef="let element"> <label mat-label
						*ngIf="element.thermostatProfile.current">
						{{ element.thermostatProfile.current.duration }}
					</label> </td>

			</ng-container>

			<!-- Grid Column -->
			<ng-container matColumnDef="outdoorTemperature">
				<th mat-header-cell *matHeaderCellDef class="fontheader"> Outdoor Temperature </th>
				<td mat-cell *matCellDef="let element"> <label mat-label
						*ngIf="element.thermostatProfile.current !== undefined">{{ element.thermostatProfile.current.outdoorTemperature}}
					</label></td>
			</ng-container>

			<!-- Home Usasge Column -->
			<ng-container matColumnDef="indoorTemperature">
				<th mat-header-cell *matHeaderCellDef class="fontheader"> Indoor Temperature </th>
				<td mat-cell *matCellDef="let element"> <label mat-label
						*ngIf="element.thermostatProfile.current !== undefined">
						{{  element.thermostatProfile.current.indoorTemperature }} </label> </td>
			</ng-container>

			<!-- Current Performance Column -->
			<ng-container matColumnDef="hvacMode">
				<th mat-header-cell *matHeaderCellDef class="fontheader"> HVAC Mode </th>
				<td mat-cell *matCellDef="let element"> <label mat-label
						*ngIf="element.thermostatProfile.current !== undefined">{{ element.thermostatProfile.current.hvacMode }}</label>
				</td>
			</ng-container>

			<!-- Gross Generation Column -->
			<ng-container matColumnDef="coolSetpoint">
				<th mat-header-cell *matHeaderCellDef class="fontheader"> Cool Setpoint </th>
				<td mat-cell *matCellDef="let element"> <label mat-label
						*ngIf="element.thermostatProfile.current !== undefined">
						{{  element.thermostatProfile.current.coolSetpoint }}
					</label> </td>
			</ng-container>

			<!-- Expected Generation Column -->
			<ng-container matColumnDef="heatSetpoint">
				<th mat-header-cell *matHeaderCellDef class="fontheader"> Heat Setpoint </th>
				<td mat-cell *matCellDef="let element"> <label mat-label
						*ngIf="element.thermostatProfile.current !== undefined">{{  element.thermostatProfile.current.heatSetpoint }}</label>
				</td>
			</ng-container>

			<!-- Consumption Cost Column -->
			<ng-container matColumnDef="status">
				<th mat-header-cell *matHeaderCellDef class="fontheader"> Status </th>
				<td mat-cell *matCellDef="let element"> <label mat-label
						*ngIf="element.thermostatProfile.current !== undefined">
						{{element.thermostatProfile.current.status  }} </label>
				</td>
			</ng-container>						

			<!-- Updated  Column -->
			<ng-container matColumnDef="updated">
				<th mat-header-cell *matHeaderCellDef class="fontheader" mat-sort-header> Updated </th>
				<td mat-cell *matCellDef="let element"> {{element.updated | date: 'MMM d, y, HH:mm'}} </td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
			<div fxFlex="100">
				<table fxFlex="100" *ngIf="userLiveMessage || (dataSource && !dataSource.data.length)">
					<tr>
						<td style="text-align: center;" [attr.colspan]="displayedColumns.length">
							{{userLiveMessage}}
						</td>
					</tr>
				</table>
			</div>
		</table>

		<div fxFlex="100" *ngIf="!userLiveMessage" class="mt-2" fxFlexAlign="end center">
			<mat-paginator [pageSizeOptions]="[50, 100, 150, 200]" showFirstLastButtons fxLayoutAlign="end">
			</mat-paginator>
		</div>

	</div>
	
</div>

