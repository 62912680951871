import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Chart } from 'angular-highcharts';
import * as _ from 'underscore';
import { Subscription } from 'rxjs';
import * as moment from 'moment-timezone';
import { PortalService } from 'src/app/service/portal.service';
import { StackedChartConfig, ChartColumnSeriesConfig, ChartLineSeriesConfig, UIUtil, SummaryView } from '@londonhydro/ui-framework-lib/ui-util-lib';
import { DecimalPipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { message } from 'src/app/framework/ui-lib/messages/message';

@Component({
  selector: 'app-open-data',
  templateUrl: './open-data.component.html',
  styleUrls: ['./open-data.component.scss']
})
export class OpenDataComponent implements OnInit, OnDestroy {

  derExplorerUrl: string;
  rtcChart: Chart;
  liveStreamOn: string;
  lastUpdatedOn: Date;
  maxRetryCount = 24;
  retryCount: number;
  liveDataSubscription: Subscription;
  liveChartDataList: Array<any>;
  chartDataLoadPending = false;
  dataLoadPending = false;
  errorMessage: string;
  userMessage: string;
  errorState = 'info';

  kpiSummaryDataCache: Map<string, any>;
  TODAY_DATE = moment.tz(UIUtil.UiTimeZone).startOf('day').toDate();
  MAX_DATE = moment.tz(UIUtil.UiTimeZone).startOf('day').subtract(1, 'day').toDate();
  MIN_DATE = moment.tz(UIUtil.UiTimeZone).startOf('year').subtract(1, 'year').toDate();
  uiTimeZone = UIUtil.UiTimeZone;
  // viewPeriod: DatePeriod = new DatePeriod();

  view: any[] = [460, 420];
  colorScheme = 'solar';
  /* colorScheme = {
    domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  }; */
  cardColor = '#232837';
  // profileSummary: object[];
  profileSummaryData: object[];
  applyUnit: any;
  ldcAssetsSummary: Array<any>;
  constructor(private portalService: PortalService, private decimalPipe: DecimalPipe, private cdRef: ChangeDetectorRef) {
    this.applyUnit = function(data: any): string {
    let unit = 'kW';
    let mv = data.value;
    if (mv && mv > 1000) {
      mv = mv / 1000;
      unit = 'MW';
    }
    mv = decimalPipe.transform(mv, '1.0-2').replace(/,/g, '');
    return mv + ' ' + unit;
  };
  }

  ngOnInit(): void {
    this.derExplorerUrl = environment.derExplorerUrl + '/public/live?showHeader=N';
    this.kpiSummaryDataCache = new Map();
    this.resetData();
    this.loadProfileData();
    this.loadLiveData();
    this.loadLdcAssets();
  }

  ngOnDestroy(): void {
    this.portalService.stopLiveStream();
    if (this.liveDataSubscription) {
      this.liveDataSubscription.unsubscribe();
    }
  }

  resetMessage(): void {
    this.userMessage = null;
    this.errorState = 'info';
  }
  loadLdcAssets(): void {
    this.portalService.getLdcAssets().subscribe(assetsSummary => {
      if (assetsSummary) {
        this.ldcAssetsSummary = assetsSummary;
      }
    });
  }
  private resetData(): void {
    this.chartDataLoadPending = false;
    this.dataLoadPending = false;
    this.liveChartDataList = new Array<any>();
    // this.profileChartDataList = new Array<any>();
    // this.viewPeriod.startDate = this.TODAY_DATE;
    // this.viewPeriod.endDate = moment.tz(this.viewPeriod.startDate, UIUtil.UiTimeZone).add(1, 'day').toDate();
    this.kpiSummaryDataCache.clear();
    this.errorMessage = null;
    this.userMessage = null;
    this.errorState = 'info';
    this.lastUpdatedOn = null;
    // this.profileSummary = new Array();
  }

  onCardSelect(event: any) {
    console.log(event);
  }

  private loadProfileData(): void {
    let endDate = moment.tz(UIUtil.UiTimeZone).endOf('month').startOf('day').toDate();
    let startDate = moment.tz(UIUtil.UiTimeZone).startOf('month').toDate();
    if (startDate.valueOf() === this.TODAY_DATE.valueOf()) {
      endDate = moment.tz(this.TODAY_DATE, UIUtil.UiTimeZone).subtract(1, 'day').toDate();
      startDate = moment.tz(endDate, UIUtil.UiTimeZone).startOf('month').toDate();
    }
    this.getDerProfileForProgram(startDate, endDate);
  }

  private getDerProfileForProgram(startDate: Date, endDate: Date): void {
    this.dataLoadPending = true;
    const summaryView = SummaryView.MONTHLY;
    this.portalService.getDerProfileForProgram(startDate, endDate).subscribe(jsonData => {
      if (jsonData) {
        this.userMessage = null;
        this.errorState = 'info';
        const lthis = this;
        _.each(jsonData, jsonObj => {
          if (jsonObj && (jsonObj.marketSegment === 'RESIDENTIAL' || jsonObj.marketSegment === '' || !jsonObj.marketSegment)) {
            lthis.kpiSummaryDataCache.set(jsonObj.programName + '-' + summaryView, jsonObj);
          }
        });
      } else {
        this.userMessage = message.dataNotAvailable;
        this.errorState = 'warning';
        this.kpiSummaryDataCache.delete('L2L' + '-' + summaryView);
        this.kpiSummaryDataCache.delete('FIT' + '-' + summaryView);
        this.kpiSummaryDataCache.delete('NET-METERING' + '-' + summaryView);
      }
      this.populateWidgetData();
      this.dataLoadPending = false;
    });
  }

  private populateWidgetData(): void {
    const summaryView = SummaryView.MONTHLY;
    const l2lData = this.kpiSummaryDataCache.get('L2L' + '-' + summaryView) || {};
    const fitData = this.kpiSummaryDataCache.get('FIT' + '-' + summaryView) || {};
    const otherData = this.kpiSummaryDataCache.get('NET-METERING' + '-' + summaryView) || {};
    const assetType = 'SOLAR';

    const l2lWidgetData = _.find(l2lData.energyMix, eobj => eobj.assetType === assetType) || {};
    const fitWidgetData = _.find(fitData.energyMix, eobj => eobj.assetType === assetType) || {};
    const otherWidgetData = _.find(otherData.energyMix, eobj => eobj.assetType === assetType) || {};

    l2lWidgetData.netZero = l2lData.netZero;
    fitWidgetData.netZero = fitData.netZero;
    otherWidgetData.netZero = otherData.netZero;

    l2lWidgetData.derMix = l2lData.derMix;
    fitWidgetData.derMix = fitData.derMix;
    otherWidgetData.derMix = otherData.derMix;

    console.log(summaryView + ' - populateWidgetData:::', l2lWidgetData, fitWidgetData, otherWidgetData);

    const profileSummary = [];
    profileSummary.push({name: 'L2L', value: l2lWidgetData.capacity});
    profileSummary.push({name: 'FIT & MicroFIT', value: fitWidgetData.capacity});
    profileSummary.push({name: 'Others', value: otherWidgetData.capacity});
    profileSummary.push({name: 'Total', value: (l2lWidgetData.capacity + fitWidgetData.capacity + otherWidgetData.capacity)});

    this.profileSummaryData = profileSummary;
    this.dataLoadPending = false;
    this.cdRef.detectChanges();
  }

  private loadLiveData(): void {
    this.renderGenerationChart();
    this.loadKpiLiveData();
  }

  private loadKpiLiveData(): void {
    const assetType = 'SOLAR';
    this.retryCount++;
    this.chartDataLoadPending = true;
    this.liveDataSubscription = this.portalService.getLiveKpiSummary(assetType).subscribe(jsonData => {
      if (!jsonData || jsonData.liveCheck) {
        console.log('Live Stream liveCheck::', jsonData);
      } else {
        this.processLiveData(jsonData);
      }
    }, error => {
      console.error(new Date() + 'error:::', error);
      this.liveStreamOn = 'N';
      this.lastUpdatedOn = null;
      if (error && error.status && error.status === 'Closed') {
        if (this.retryCount < this.maxRetryCount) {
          console.log('Restarting the Live Stream...');
          this.loadKpiLiveData();
        } else {
          this.chartDataLoadPending = false;
          this.userMessage = message.liveStreamServerError;
          this.errorState = 'danger';
          console.log('Live stream ended due to server error.');
        }
      }
    });
  }

  private processLiveData(liveData: any): void {
    if (liveData && !_.isEmpty(liveData)) {
      this.liveStreamOn = 'Y';
      this.lastUpdatedOn = liveData.updated;
      this.userMessage = null;
      this.errorState = 'info';
      const assetType = 'SOLAR';
      /*** Live Data Form ***/
      if ((liveData.segmentName === 'L2L' || liveData.segmentName === 'FIT' || liveData.segmentName === 'NET-METERING')
        && liveData.subSegmentName === assetType) {
        console.log('Live Stream::', liveData);
        let liveProfile = this.kpiSummaryDataCache.get(liveData.segmentName + '-' + SummaryView.LIVE) || { assetProfile: {} };
        liveProfile = _.extend(liveProfile, liveData);

        this.kpiSummaryDataCache.set(liveData.segmentName + '-' + SummaryView.LIVE, liveProfile);
        console.log('liveProfile::', liveProfile);
        // this.updateLiveSummary();

        if (this.rtcChart) {
          if (liveProfile.segmentName === 'L2L') {
            const hourlyProfileData = liveProfile.hourlyProfile;
            let hourlyScheduleData = liveProfile.hourlySchedule;
            let hourlyLoadData = liveProfile.loadProfile ? liveProfile.loadProfile.hourlyProfile : null;

            hourlyScheduleData = hourlyScheduleData ? _.map(hourlyScheduleData, function (obj, key) { return obj; }) : null;
            hourlyLoadData = hourlyLoadData ? _.map(hourlyLoadData, function (obj, key) { return obj; }) : null;

            this.kpiSummaryDataCache.set(liveProfile.segmentName + '-' + SummaryView.LIVE + '-schedule', hourlyScheduleData);
            this.kpiSummaryDataCache.set(liveProfile.segmentName + '-' + SummaryView.LIVE + '-load', hourlyLoadData);

            this.liveChartDataList = hourlyProfileData ? _.map(hourlyProfileData, function (obj, key) { return obj; }) : null;
            this.chartDataLoadPending = false;

            setTimeout(() => {
              this.renderChartSeries(true);
            }, 300);
          }
        }
      }
    } else {
      this.userMessage = message.liveStreamNotReceived;
      this.errorState = 'info';
    }
  }

  private renderGenerationChart(): void {
    const chartOptions = new StackedChartConfig();
    // const chartDateFormat = UIUtil.UiDateFormat;
    const xAxis: any = _.extend({}, chartOptions.xAxis);
    xAxis.type = 'datetime';
    xAxis.dateTimeLabelFormats = 'hour';
    xAxis.tickInterval = 60 * 60 * 1000;
    xAxis.minRange = 24 * 60 * 60 * 1000;
    xAxis.labels.format = '{value:%H:%M}';
    xAxis.min = this.TODAY_DATE.getTime();
    xAxis.max = moment.tz(this.TODAY_DATE, UIUtil.UiTimeZone).startOf('day').add(1, 'day').subtract(1, 'h').toDate().getTime();
    xAxis.labels.rotation = -45;
    xAxis.title.enabled = false;
    // xAxis.title.text = '<p style="color:#004343;font-size:13px;">' + moment(this.TODAY_DATE).format(chartDateFormat) + '</p>';
    chartOptions.xAxis = xAxis;
    chartOptions.yAxis[0].type = 'logarithmic';
    chartOptions.yAxis[0].title.text = '<p style="color:#004343;font-size:13px;">Generation (kWh)</p>';
    chartOptions.yAxis[0].gridLineColor = '#EFEFEF';
    chartOptions.yAxis[0].gridLineWidth = 1;
    chartOptions.yAxis[0].lineWidth = 1;
    chartOptions.yAxis[0].offset = 10;
    chartOptions.plotOptions.column = {
      pointPadding: 0,
      groupPadding: 0,
      pointWidth: 10,
      minPointLength: 3
    };
    chartOptions.tooltip = {
      shared: true,
      useHTML: true,
      xDateFormat: '%l:%M %p'
    };
    chartOptions.colors = ['#4EBA70'];
    chartOptions.legend.enabled = false;
    chartOptions.lang.noData = 'No generation data to display';

    this.rtcChart = new Chart(chartOptions);
    setTimeout(() => {
      this.renderChartSeries();
    }, 300);
  }

  private renderChartSeries(redraw?: boolean): void {
    if (redraw && this.rtcChart && this.rtcChart.ref) {
      const scount = this.rtcChart.ref.series.length;
      for (let i = scount - 1; i >= 0; i--) {
        this.rtcChart.removeSeries(i);
      }
    }
    const cthis = this;
    const chartDataList = this.liveChartDataList;

    console.log('chartDataList::', chartDataList);
    let chartData: Array<any> = null;
    if (chartDataList && !_.isEmpty(chartDataList)) {
      chartData = _.map(chartDataList, dataObj => {
        const yValue = dataObj.generation != null ? cthis.decimalPipe.transform(dataObj.generation, '1.0-2').replace(/,/g, '') : '0';
        return [moment(dataObj.startTime).valueOf(), +yValue];
      });
    }
    console.log('processed chartData::', chartData);
    const consumptionSeries = new ChartColumnSeriesConfig('Generation');
    if (!chartData) {
      chartData = new Array();
    }
    consumptionSeries.data = chartData;
    // this.generationToday =  _.reduce(chartData, function(memo, cData){ return memo + cData[1]; }, 0);
    consumptionSeries.tooltip = { valueSuffix: ' kWh' };
    this.rtcChart.addSeries(consumptionSeries as Highcharts.SeriesColumnOptions, true, false);

    const hourlyScheduleData = this.kpiSummaryDataCache.get('L2L-' + SummaryView.LIVE + '-schedule');
    const hourlyLoadData = this.kpiSummaryDataCache.get('L2L-' + SummaryView.LIVE + '-load');
    console.log('hourlyScheduleData:::', hourlyScheduleData);
    console.log('hourlyLoadData:::', hourlyLoadData);
    const xMin = this.rtcChart.ref.options.xAxis[0].min;
    const xMax = this.rtcChart.ref.options.xAxis[0].max;
    if (hourlyScheduleData && !_.isEmpty(hourlyScheduleData)) {
      let scheduleChartData = _.map(hourlyScheduleData, dataObj => {
        const yValue = dataObj.generation != null ? cthis.decimalPipe.transform(dataObj.generation, '1.0-2').replace(/,/g, '') : '0';
        return [moment(dataObj.startTime).valueOf(), +yValue];
      });
      scheduleChartData = _.filter(scheduleChartData, function (cdata) {
        return cdata[0] >= xMin && cdata[0] <= xMax;
      });
      scheduleChartData = _.sortBy(scheduleChartData, function (obj) { return obj[0]; });
      console.log('hourlyscheduleChartData:::', scheduleChartData);
      const hourlyScheduleSeries = new ChartLineSeriesConfig('Expected Generation');
      hourlyScheduleSeries.dashStyle = 'ShortDash';
      hourlyScheduleSeries.color = '#FFBF2D';
      hourlyScheduleSeries.data = scheduleChartData;
      // this.expectedGenerationForToday = _.reduce(scheduleChartData, function (memo, cData) { return memo + cData[1]; }, 0);
      hourlyScheduleSeries.tooltip = { valueSuffix: ' kWh' };
      this.rtcChart.addSeries(hourlyScheduleSeries as Highcharts.SeriesLineOptions, true, false);
    }
    /* if (hourlyLoadData && !_.isEmpty(hourlyLoadData)) {
      let loadChartData = _.map(hourlyLoadData, dataObj => {
        const yValue = dataObj.consumption != null ? cthis.decimalPipe.transform(dataObj.consumption, '1.0-2').replace(/,/g, '') : '0';
        return [moment(dataObj.startTime).valueOf(), +yValue];
      });
      loadChartData = _.filter(loadChartData, function (cdata) {
        return cdata[0] >= xMin && cdata[0] <= xMax;
      });
      loadChartData = _.sortBy(loadChartData, function (obj) { return obj[0]; });
      console.log('loadChartData:::', loadChartData);
      const homeLoadSeries = new ChartLineSeriesConfig('Home Consumption');
      homeLoadSeries.color = '#AA0000';
      homeLoadSeries.data = loadChartData;
      // homeLoadSeries.yAxis = 1;
      homeLoadSeries.tooltip = { valueSuffix: ' kWh' };
      this.rtcChart.addSeries(homeLoadSeries as Highcharts.SeriesLineOptions, true, false);
    } */
    this.cdRef.detectChanges();
  }

}
