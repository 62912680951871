<div fxLayout="row wrap" fxLayoutAlign="space-between center" class="page-content p-2">
	<!-- <div fxLayout="row wrap" fxFlex="100" [ngClass]="'my-3'"></div> -->	
	<div fxFlex="100" fxLayout="row wrap" [fxFlex.lt-md]="100" fxLayoutAlign="space-between stretch" class="my-3">
		<uilib-widget-card fxFlex="100" [widgetTitle]="'LDC Energy Flow'"
	      [widgetTitleIcon]="'icon-my-consumption'">
	      <app-ldc-energy-widget [widgetData]="ldcAssetsSummary"></app-ldc-energy-widget>
	    </uilib-widget-card>
		<mat-card fxLayout="row wrap" fxFlex="49" [fxFlex.lt-md]="100" class="card mat-elevation-z2 p-0">
			<mat-card-header fxFlex="100" class="card-header">
				<h2 class="card-title mb-0">Today's Generation (L2L)</h2>
			</mat-card-header>
			<mat-card-content fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center" class="card-body">
				<div class="rtc-chart" fxFlex="100" [chart]="rtcChart"></div>
				<div fxLayout="column" fxFlex="100" fxLayoutAlign="center stretch" *ngIf="chartDataLoadPending">
					<div class="loading-text blinking"><span>Fetching live data...</span></div>
					<mat-progress-bar mode="indeterminate"></mat-progress-bar>
				</div>
				<div fxFlex="100" class="loading-text blinking text-info float-right px-2" *ngIf="!chartDataLoadPending">
					<span>Live Generation (upto last hour)</span>
				</div>
			</mat-card-content>
		</mat-card>

		<mat-card fxLayout="row wrap" fxFlex="49" [fxFlex.lt-md]="100" class="card mat-elevation-z2 p-0" [ngClass.lt-md]="'mt-2'">
			<mat-card-header fxFlex="100" class="card-header">
				<h2 class="card-title mb-0">Generation Capacity</h2>
			</mat-card-header>
			<mat-card-content fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center" class="card-body">
				<ngx-charts-number-card [view]="view" [scheme]="colorScheme" [results]="profileSummaryData" [cardColor]="cardColor"
					[valueFormatting]="applyUnit" (select)="onCardSelect($event)">
				</ngx-charts-number-card>
				<div fxLayout="column" fxFlex="100" fxLayoutAlign="center stretch" *ngIf="dataLoadPending">
					<div class="loading-text blinking"><span>Fetching Summary...</span></div>
					<mat-progress-bar mode="indeterminate"></mat-progress-bar>
				</div>
			</mat-card-content>
		</mat-card>
	</div>

	<mat-card fxFlex="100" class="card mat-elevation-z2 p-0">
		<mat-card-header fxFlex="100" class="card-header">
			<h2 class="card-title mb-0">Explore DER Resources</h2>
		</mat-card-header>
		<mat-card-content fxFlex="100" fxLayoutAlign="center center" class="card-body p-1">
			<embed *ngIf="derExplorerUrl" [src]="derExplorerUrl | safeUrl" target="self" class="w-100 h-100 i-frame" />
		</mat-card-content>
	</mat-card>
</div>