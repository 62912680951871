import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseLayoutComponent } from './base-layout.component';
import { LayoutRoutingModule } from './layout-routing.module';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { TopMenuComponent } from './components/top-menu/top-menu.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { PageLoaderComponent } from './components/page-loader/page-loader.component';
import { AngularMaterialModule } from '@londonhydro/ui-framework-lib';


@NgModule({
  declarations: [BaseLayoutComponent, HeaderComponent, FooterComponent, PageTitleComponent, TopMenuComponent, SideMenuComponent,
    PageNotFoundComponent, PageLoaderComponent ],
  imports: [
    CommonModule,
    AngularMaterialModule,
    LayoutRoutingModule,
  ],
  exports: [LayoutRoutingModule, HeaderComponent, FooterComponent, TopMenuComponent, BaseLayoutComponent,
    AngularMaterialModule, PageLoaderComponent ],
  providers: []
})
export class LayoutModule { }
