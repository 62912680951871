<div fxLayout="row wrap" fxLayoutAlign="center center" class="page-content bg-white p-2">
	<div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="end center" class="mt-4 mr-3">
		<mat-form-field class="mr-2 filter-list" floatLabel="never" fxShow fxHide.lt-md="true" appearance="fill">
			<input matInput (keyup)="filterByAppName($event.target.value)" placeholder="Search">
			<img matSuffix class="icon-search" src="/assets/images/landingpage/logo/icon_search.png">
		</mat-form-field>
		<div fxFlex.lt-md="100" fxLayout="row wrap" class="filter-list">
			<mat-form-field fxFlex.lt-md="100" class="mr-2" appearance="fill">
				<!-- <mat-label>Types</mat-label> -->
				<mat-select [(value)]="selectedAppType" (selectionChange)="filterByAppType($event)">
					<mat-option *ngFor="let type of appTypes" [value]="type">{{type.label}}</mat-option>
				</mat-select>
			</mat-form-field>
			<mat-form-field fxFlex.lt-md="100" appearance="fill">
				<!-- <mat-label>Categories</mat-label> -->
				<mat-select [(value)]="selectedAppCategory" (selectionChange)="filterByAppCategory($event)">
					<mat-option *ngFor="let category of appCatagories" [value]="category">{{category.label}}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<mat-form-field floatLabel="never" fxHide fxShow.lt-md="true">
			<input matInput placeholder="Search">
		</mat-form-field>
	</div>
	<div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-evenly center" class="bg-white">
		<mat-card class="app-card mb-3 p-5 mat-elevation-z2" fxFlex="32" fxFlex.lt-md="100" fxLayout="row wrap" *ngFor="let app of filteredApps">
			<div fxLayout="row" fxFlex="100" fxLayoutAlign="end" *ngIf="app.isThirdPartyApp">
				<img mat-card-sm-image class="icon-app" src="/assets/images/landingpage/logo/app.png">
			</div>
			<div fxLayout="row" fxFlex="100" fxLayoutAlign="end" *ngIf="app.isGBPlatformApp">
				<img mat-card-sm-image class="icon-app" src="/assets/images/landingpage/logo/data.png">
			</div>
			<div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
				<img mat-card-image [ngClass]="app.appLogoClass" src="{{app.appLogoUrl}}" />
			</div>
			<mat-card-content class="mt-4">
				<div [innerHTML]="app.bodyTextContent"></div>
			</mat-card-content>
			<mat-card-footer fxFlex="100" fxLayoutGap="40px" fxLayoutAlign="center center">
				<button class="btn btn-light btn-lg btn-app-store" *ngIf="app.hasIOSApp" (click)="navigateTo(app.iOSApp)">
				</button>
				<button type="button" class="btn btn-light btn-lg btn-play-store" *ngIf="app.hasAndroidApp" (click)="navigateTo(app.androidApp)">
				</button>

				<div fxLayout="row" class="ml-4"  fxLayoutAlign="center center"
					*ngIf="app.gbCertification !== null && app.gbCertification.cmdCompliance">
					<img mat-card-sm-image class="icon-gb" src="/assets/images/der/solar/ic_GreenButton.png">
				</div>
				<div fxLayout="row" fxLayoutAlign="center center"
					*ngIf="app.gbCertification !== null && app.gbCertification.dmdCompliance">
					<img mat-card-sm-image class="icon-gb" src="/assets/images/der/solar/ic_GreenButton.png">
				</div>
				<div fxLayout="row" fxLayoutAlign="center center"
					*ngIf="app.gbCertification !== null && app.gbCertification.partnerCompliance">
					<img mat-card-sm-image class="icon-gb" src="/assets/images/der/solar/ic_GreenButton.png">
				</div>
			</mat-card-footer>
		</mat-card>
	</div>
</div>