import { DecimalPipe, LocationStrategy } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { IUser } from 'src/app/framework/shared/model/iuser';
import { UserSessionService } from 'src/app/framework/shared/service/user-session.service';
import { User } from 'src/app/modules/auth/model/user';

import * as _ from 'underscore';
import { DatePeriod, SummaryView, UIUtil } from '@londonhydro/ui-framework-lib/ui-util-lib';
import * as moment from 'moment-timezone';
import { MenuItem } from 'src/app/framework/shared/model/menuitems';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SummaryViews } from 'src/app/framework/ui-lib/model/summaryviews';
import { MatDatepicker } from '@angular/material/datepicker';
import { EvassetService } from '../../service/evasset.service';
import { MatSort } from '@angular/material/sort';
import { message } from 'src/app/framework/ui-lib/messages/message';

@Component({
  selector: 'app-newevasset',
  templateUrl: './newevasset.component.html',
  styleUrls: ['./newevasset.component.scss']
})
export class NewevassetComponent implements OnInit {

  constructor(private evAssetService: EvassetService, private decimalPipe: DecimalPipe,
    private locationStrategy: LocationStrategy, private userSessionService: UserSessionService, 
    private router: Router, private fb: FormBuilder) {
    history.pushState(null, null, location.href);
    this.locationStrategy.onPopState(() => {
      history.pushState(null, null, location.href);
    })
    this.dateForm = this.fb.group({
      dateSelect: ''
    });
  }


  masterAssetList: Array<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  loggedUser: IUser;
  userSubscription: Subscription;
  smallScreen = false;

  customerAddress: string;
  pageHeader: string;
  summaryViews = [];
  summaryView: string;
  currentView: string;
  currentViewIndex = 0;

  dataLoadPending = false;
  accessProhibited: boolean;
  userMessage: string;
  deviceInfo: string;
  errorState = 'info';
  showEvInfo = true;

  /** Added for date range */
  uiTimeZone = UIUtil.UiTimeZone;
  periodDateFormat = 'MMM dd, yyyy';
  MAX_DATE: Date;
  MIN_DATE: Date;
  dateForm: FormGroup;
  viewPeriod: DatePeriod = new DatePeriod();
  disablePreviousPeriod = true;
  disableNextPeriod = true;

  topMenuItems: MenuItem[];
  tabViews = [];
  selectedView: any;

  @Input() menuItems: MenuItem[];

  hasEV: boolean;
  hasCharger: boolean;

  evData: any;
  evSummaryProfile: any;
  evProfileList: Array<any>;
  evCommunityProfileList: Array<any>;

  evDataLoaded = false;
  evSummaryLoaded = false;
  evProfileListLoaded = false;
  evCommunityProfileListLoaded = false;
  maxStartTime: Date;
  historyCount = 0;
  maxHistoryCheck = 3;
  firstDataLoad = true;
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['index', 'accountId', 'totalCost', 'evBillCost', 'remainingBillCost', 'avoidedGasCost', 'gasolineAvoided', 'totalDistanceTravelled', 'totalSpentOnCharging', 'costPerKm', 'co2Offset', 'updated'];


  ngOnInit(): void {
    this.smallScreen = this.userSessionService.mobileMode;
    this.userSubscription = this.userSessionService.loggedUser.subscribe(user => {
      if (user) {
        user.customFields = user.customFields || {};
        if (user !== this.loggedUser || (this.loggedUser.customFields.accountId !== user.customFields.accountId)
          || (this.loggedUser.customFields.LDC !== user.customFields.LDC)) {
          console.log('change in user detected..loading data ...');
          this.loggedUser = _.extendOwn(new User(), user);
        }
      }
    });
    this.loadInitialData();
  }

  refreshEvent(): void {
    console.log('refresh event: ');
    this.getEvAssetList();
  }

  private getEvAssetList(datePeriod?: DatePeriod): void {
    this.dataLoadPending = true;
    datePeriod = datePeriod || this.viewPeriod;
    console.log('this.summaryView:: ', this.summaryView);
    this.dataLoadPending = true;
    this.masterAssetList = [];
    this.dataSource = null;

    this.evAssetService.getEVAssetList(datePeriod.startDate, datePeriod.endDate, this.summaryView).subscribe(jsonData => {

      this.masterAssetList = jsonData;
      const cthis = this;

      console.log('Display user data: ', this.masterAssetList);

      if (this.masterAssetList && !_.isEmpty(this.masterAssetList)) {
        this.userMessage = null;
        this.errorState = 'info';
        const lthis = this;

        const filteredList = _.filter(this.masterAssetList, function (ap) {

          ap.estimatedBillCost = ap.energyCost || ap.chargingCost;

          ap.remainingBillCost = (ap.homeEnergyCost !== undefined && ap.homeEnergyCost !== null) ?
          (ap.homeEnergyCost - (ap.estimatedBillCost || 0)) : null;

          return ap;

        });

        console.log('masterAssetList List:::', filteredList);
        this.dataSource = new MatTableDataSource(filteredList);

        setTimeout(() => {
          this.dataSource.paginator = this.paginator;
          }, 300);
        this.dataSource.sort = this.sort;
        this.dataLoadPending = false;
      } else {
        this.userMessage = message.noDataFound;
        this.errorState = 'warning';
      }
      this.dataLoadPending = false;

    });
  }

  navigateToDashboard(account: any): void {
    console.log('onAccountIdChange account id...', account);
    if (this.loggedUser) {
      this.loggedUser.customFields.accountId = account.accountId;
      this.userSessionService.setLoggedUser(this.loggedUser);
      this.router.navigate(['/asset']);
    }
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  private loadDefaultMenu(): void {
    const lthis = this;
    let sMenu = _.find(this.topMenuItems, (menu: MenuItem) => {
      return lthis.router.url === menu.action;
    });
    if (!sMenu) {
      sMenu = _.find(this.topMenuItems, (menu: MenuItem) => {
        return lthis.router.url.startsWith(menu.action);
      });
    }
    sMenu = sMenu || this.topMenuItems[0];
    if (sMenu) {
      this.router.navigate([sMenu.action]);
    }
  }

  resetMessage(): void {
    this.userMessage = null;
    this.errorState = 'info';
  }

  updateDateBoundary(): void {
    this.MAX_DATE = moment.tz(this.uiTimeZone).startOf('day').subtract(1, 'day').toDate();
    this.MIN_DATE = moment.tz(this.uiTimeZone).startOf('year').subtract(2, 'year').toDate();
  }

  hideEvInfo(): void {
    this.showEvInfo = false;
  }

  viewEvInfo(): void {
    this.showEvInfo = true;
  }

  private resetData(): void {
    this.resetMessage();
    this.resetSummaryViews(null);
    this.customerAddress = '';
    this.dataLoadPending = false;
    this.updateDateBoundary();
    this.viewPeriod = DatePeriod.getDefaultPeriod(this.summaryView, this.uiTimeZone);
    this.validatePeriodRange();
    this.evData = null;
    this.evSummaryProfile = null;
    this.evProfileList = null;
    this.evCommunityProfileList = null;
    this.historyCount = 0;
  }

  private loadInitialData(): void {
    this.resetData();
    this.getEvAssetList();
  }

  private resetSummaryViews(granularity: string): void {
    console.log('ResetSummaryViews granularity:' + granularity);
    this.summaryViews = SummaryViews.ALL_VIEWS_EVASSET;
    if (granularity) {
      if (granularity.startsWith('Hour')) {
        granularity = SummaryView.DAILY;
      }
      const startIndex = _.indexOf(this.summaryViews, _.find(this.summaryViews, function(v: string) { return v.startsWith(granularity); }));
      this.summaryViews = _.rest(this.summaryViews, startIndex < 0 ? 0 : startIndex);
      console.log(_.indexOf(this.summaryViews, granularity) + '-this.summaryViews:::', this.summaryViews);
    }
    this.summaryView = this.summaryViews[0];
    this.currentViewIndex = _.indexOf(this.summaryViews, this.summaryView);
  }

  changeSummaryView(event: any): void {
    //this.dataLoadPending = true;
    this.masterAssetList = [];
    this.dataSource = null;
    this.summaryView = this.summaryViews[event.index];

    /** Changes for maxStartTime starts here.. */
    if (this.summaryView === SummaryView.LIVE || this.summaryView === SummaryView.LIVE2) {
      this.viewPeriod = DatePeriod.getDefaultPeriod(this.summaryView, this.uiTimeZone);
    } else {
      if (this.maxStartTime && this.maxStartTime !== undefined) {
        this.viewPeriod = DatePeriod.getSelectedPeriod(this.maxStartTime, this.summaryView, this.uiTimeZone);
      } else {
        this.viewPeriod = DatePeriod.getDefaultPeriod(this.summaryView, this.uiTimeZone);
      }
    }
    this.periodDateFormat = this.summaryView === SummaryView.YEARLY ? 'yyyy' : (this.summaryView === SummaryView.MONTHLY ? 'MMMM, yyyy' : 'MMM dd, yyyy');
    this.validatePeriodRange();
    this.getEvAssetList();
  }

  viewPreviousPeriod(): void {
    this.viewPeriod = DatePeriod.getPreviousPeriod(this.summaryView, this.viewPeriod, this.uiTimeZone);
    this.validatePeriodRange();
    this.getEvAssetList();
  }

  viewNextPeriod(): void {
    this.viewPeriod = DatePeriod.getNextPeriod(this.summaryView, this.viewPeriod, this.uiTimeZone);
    this.validatePeriodRange();
    this.getEvAssetList();
  }

  viewPeriodChangeHandler(normalizedDate?: moment.Moment, datepicker?: MatDatepicker<moment.Moment>): void {
    console.log('normalizedMonth::', normalizedDate);
    let selectedDate = null;
    if (normalizedDate) {
      selectedDate = moment.tz(normalizedDate, this.uiTimeZone).toDate();
      datepicker.close();
    } else {
      selectedDate = moment.tz(this.dateForm.controls.dateSelect.value, this.uiTimeZone).toDate();
    }
    if (selectedDate !== null) {
      this.viewPeriod = DatePeriod.getSelectedPeriod(selectedDate, this.summaryView, this.uiTimeZone);
      this.validatePeriodRange();
      this.getEvAssetList();
    }
  }

  private validatePeriodRange(): void {
    if (this.viewPeriod.endDate >= this.MAX_DATE) {
      this.disableNextPeriod = true;
    } else {
      this.disableNextPeriod = false;
    }
    if (this.viewPeriod.startDate <= this.MIN_DATE) {
      this.disablePreviousPeriod = true;
    } else {
      this.disablePreviousPeriod = false;
    }
  }

}
