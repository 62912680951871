
	<div fxLayout="column" fxFlex="100">
		<!--<div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="end">
			<button type="button" class="btn btn-link ml-2" (click)="refreshEvent()">Refresh </button>
		</div>-->
	<div fxLayout="row wrap" fxLayoutAlign="space-between start">
	  <app-page-loader *ngIf="dataLoadPending" class="app-loader"></app-page-loader> 
	  <div fxFlex="100" fxLayout="row wrap" class="tile-card">
		<div fxFlex="100" class="tile-card-header py-3" fxLayout="row wrap" fxLayoutAlign="space-between start">
		  <!--<h1 fxFlex.lt-md="100">{{pageHeader}}</h1>-->
		  <button type="button" class="btn btn-link ml-2" (click)="refreshEvent()"> </button>
		  <div fxFlex.lt-md="100" fxLayout="row wrap" fxLayoutAlign="end center">
			<button mat-mini-fab class="blue-button left rounded-button mr-2" (click)="viewPreviousPeriod()" [disabled]="disablePreviousPeriod">
			  <i class="ace-icon fa fa-chevron-left ace-icon-align"
				matTooltip="{{disablePreviousPeriod? 'You are viewing the first period of available data' : 'View previous period data'}}"></i>
			</button>
			<div style="font-weight: bold;" *ngIf="viewPeriod">
			  <span style="font-size: 18px">{{viewPeriod.startDate| date : periodDateFormat: uiTimeZone}}</span>
			  <span style="font-size: 18px" *ngIf="summaryView === 'Weekly'"> - {{viewPeriod.endDate| date : periodDateFormat: uiTimeZone}}</span>
			  <mat-form-field [formGroup]="dateForm" class="date-picker-custom">
				<input matInput type="text" [matDatepicker]="picker" (dateChange)="viewPeriodChangeHandler()" formControlName="dateSelect"
				  [min]="MIN_DATE" [max]="MAX_DATE" style="display: none;">
				<mat-datepicker-toggle matSuffix [for]="picker" class="btn-date-picker"></mat-datepicker-toggle>
				<mat-datepicker touchUi="{{smallScreen}}" #picker startView="{{summaryView === 'Yearly'? 'multi-year' : (summaryView === 'Monthly'?'year':'month')}}"
				  [startAt]="viewPeriod.startDate" (yearSelected)="viewPeriodChangeHandler($event, picker)"
				  (monthSelected)="viewPeriodChangeHandler($event, picker)"></mat-datepicker>
			  </mat-form-field>
			</div>
	
			<button mat-mini-fab class="blue-button rounded-button ml-2" (click)="viewNextPeriod()" [disabled]="disableNextPeriod">
			  <i class="ace-icon fa fa-chevron-right ace-icon-align"
				matTooltip="{{disableNextPeriod? 'You are viewing the last period of available data' : 'View next period data'}}"></i>
			</button>
		  </div>
		</div>
	
		<div fxFlex="100" class="tile-card-header" fxLayout="row wrap" fxLayoutAlign="end">
		  <div fxFlex="100" fxHide fxShow.lt-md class="tile-card-header mb-2" fxLayoutAlign="end">
			<h2 class="m-0 text-right">{{customerAddress}}</h2>
		  </div>
		  <div fxFlex="100" fxFlex.lt-md="100" fxLayout="row wrap" class="filter-view">
			<div class="text-nowrap" [ngClass.lt-md]="'my-2'">Filter by:</div>
			<mat-tab-group class="tab-menu-group toggle-group" (selectedTabChange)="changeSummaryView($event)" [selectedIndex]="currentViewIndex">
			  <mat-tab *ngFor="let view of summaryViews;let index = index; let last = last;">
				<ng-template mat-tab-label>
				  <span mat-icon-button style="font-weight: bold;">{{view}}</span>
				</ng-template>
			  </mat-tab>
			</mat-tab-group>
		  </div>
		  
		  <!-- <div *ngIf="summaryView === 'Weekly'" fxFlex="50" fxShow fxHide.lt-md  class="tile-card-header" fxLayoutAlign="end">			
			<button type="button" class="btn btn-link ml-2" (click)="refreshEvent()">Refresh </button>
		  </div> -->
		</div>
		<uilib-message-bar fxFlex="100" class="mt-0" [message]="deviceInfo" [messageType]="'warning'"
		  [showMessage]="showEvInfo && evDataLoaded" (onclose)="hideEvInfo()"></uilib-message-bar>
	  </div>
	
	  <!-- <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-between center">
		<div fxFlex="100" fxLayout="row" class="py-3" fxLayoutAlign.lt-md="end">
		  <app-top-menu fxFlex="100" [menuItems]="topMenuItems"></app-top-menu>
		</div>
	
		<div fxFlex="100" class="w-100 h-100 bg-transparent py-2" *ngIf="!accessProhibited">
		  <app-page-loader *ngIf="dataLoadPending" class="app-loader"></app-page-loader>
		  <router-outlet></router-outlet>
		</div>
	  </div> -->
	</div>

	<div fxLayout="row wrap" fxLayoutAlign="space-between start" class="mt-2">
	<mat-table fxFlex="100" class="matTable custom-table mat-elevation-z8 mb-2" matSort [dataSource]="dataSource" multiTemplateDataRows>
		<ng-container matColumnDef="index" sticky>
		  <mat-header-cell *matHeaderCellDef class="text-blue table-header column-index fontheader">Index</mat-header-cell>
		  <mat-cell class="column-index" *matCellDef="let element; let i = dataIndex">{{i + 1}}</mat-cell>
		</ng-container>

		<!-- Account Id Column -->
		<ng-container matColumnDef="accountId">
			<mat-header-cell *matHeaderCellDef mat-sort-header class="text-blue table-header fontheader"> Account Id </mat-header-cell>
			<mat-cell *matCellDef="let element"> <button type="button" class="btn btn-link" (click)="navigateToDashboard(element)">{{element.accountId}} </button></mat-cell>
		</ng-container>
  
		<ng-container matColumnDef="totalCost">
		  <mat-header-cell *matHeaderCellDef class="text-blue table-header fontheader"> Total Cost </mat-header-cell>
		  <mat-cell *matCellDef="let element" class="matCell"> {{element.homeEnergyCost | currency:'CAD':'symbol-narrow':'1.0-2'}}  </mat-cell>
		</ng-container>
  
		<ng-container matColumnDef="evBillCost">
		  <mat-header-cell *matHeaderCellDef class="text-blue table-header fontheader"> EV Cost Towards Bill </mat-header-cell>
		  <mat-cell *matCellDef="let element" class="matCell"> {{element.estimatedBillCost | currency:'CAD':'symbol-narrow':'1.0-2'}} </mat-cell>
		</ng-container>

		<ng-container matColumnDef="remainingBillCost">
			<mat-header-cell *matHeaderCellDef class="text-blue table-header fontheader"> Remaining Cost of Bill </mat-header-cell>
			<mat-cell *matCellDef="let element" class="matCell"> {{element.remainingBillCost | currency:'CAD':'symbol-narrow':'1.0-2'}} </mat-cell>
		</ng-container>

		<ng-container matColumnDef="avoidedGasCost">
			<mat-header-cell *matHeaderCellDef class="text-blue table-header fontheader"> Avoided Gas Cost </mat-header-cell>
			<mat-cell *matCellDef="let element" class="matCell"> {{element.gasCost | currency:'CAD':'symbol-narrow':'1.0-2'}} </mat-cell>
		</ng-container>

		<ng-container matColumnDef="gasolineAvoided">
			<mat-header-cell *matHeaderCellDef class="text-blue table-header fontheader"> Litres of gasoline avoided (L) </mat-header-cell>
			<mat-cell *matCellDef="let element" class="matCell"> {{element.gas |  number : '1.0-2'}} </mat-cell>
			<!--<mat-cell *matCellDef="let element" class="matCell"> {{element.energyProfile.generationProfile.SOLAR.profile.generation}} </mat-cell>-->
		</ng-container>

		<ng-container matColumnDef="totalDistanceTravelled">
			<mat-header-cell *matHeaderCellDef class="text-blue table-header fontheader"> Total Distance Travelled (km) </mat-header-cell>
			<mat-cell *matCellDef="let element" class="matCell"> {{element.distance |  number : '1.0-2'}} </mat-cell>
		</ng-container>

		<ng-container matColumnDef="totalSpentOnCharging">
			<mat-header-cell *matHeaderCellDef class="text-blue table-header fontheader"> Total Spent On Charging </mat-header-cell>
			<mat-cell *matCellDef="let element" class="matCell"> {{element.energyCost | currency:'CAD':'symbol-narrow':'1.2-2'}} </mat-cell>
		</ng-container>

		<ng-container matColumnDef="costPerKm">
			<mat-header-cell *matHeaderCellDef class="text-blue table-header fontheader"> Cost Per KM </mat-header-cell>
			<mat-cell *matCellDef="let element" class="matCell"> {{element.energyCostPerKm | currency:'CAD':'symbol-narrow':'1.0-2'}} </mat-cell>
		</ng-container>

		<ng-container matColumnDef="co2Offset">
			<mat-header-cell *matHeaderCellDef class="text-blue table-header fontheader"> CO2 Offset </mat-header-cell>
			<mat-cell *matCellDef="let element" class="matCell"> {{element.co2Offset.value |  number : '1.0-2'}} </mat-cell>
		</ng-container>

		<!-- Updated  Column -->
		<ng-container matColumnDef="updated">
			<mat-header-cell *matHeaderCellDef class="text-blue table-header fontheader" mat-sort-header> Updated </mat-header-cell>
			<mat-cell *matCellDef="let element" class="matCell"> {{element.updated | date: 'MMM d, y, HH:mm'}} </mat-cell>
		</ng-container>			
  
		<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
		<mat-row *matRowDef="let row; columns: displayedColumns;" matRipple class="element-row"></mat-row>
	  </mat-table>
	  <div fxFlex="100" *ngIf="!userMessage" fxFlexAlign="end center">
		<mat-paginator [pageSizeOptions]="[50, 100, 150, 200]" showFirstLastButtons fxLayoutAlign="end">
		</mat-paginator>
	</div>
	  <div fxFlex="100">
		<table fxFlex="100" *ngIf="userMessage || (dataSource && !dataSource.data.length)">
		  <tr>
			<td style="text-align: center;" [attr.colspan]="displayedColumns.length">
			  {{userMessage}}
			</td>
		  </tr>
		</table>
	  </div>
	</div>
</div>

			

			  
	
<!--</app-base-layout>-->