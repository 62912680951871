import { Component, OnInit } from '@angular/core';
import * as _ from 'underscore';
import { Application } from 'src/app/model/application';
import { AppType } from 'src/app/model/apptype';
import { PortalService } from 'src/app/service/portal.service';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-our-solutions',
  templateUrl: './our-solutions.component.html',
  styleUrls: ['./our-solutions.component.scss']
})
export class OurSolutionsComponent implements OnInit {

  appTypes = [
    { value: 'all', label: 'All types' },
    { value: 'water', label: 'Water' },
    { value: 'gas', label: 'Gas' },
    { value: 'electricity', label: 'Electric' }
  ];

  appCatagories = [
    { value: 'all', label: 'All categories' },
    { value: 'thirdPartyApp', label: 'Third Party App' },
    { value: 'gbPlatform', label: 'GB Platform' }
  ];

  private solutionApps: Application[];
  filteredApps: Application[];

  selectedAppType: any;
  selectedAppCategory: any;
  searchKey: any = '';

  constructor(private portalService: PortalService) { }

  ngOnInit(): void {
    this.selectedAppType = this.appTypes[0];
    this.selectedAppCategory = this.appCatagories[0];
    this.loadMatCardDataConfig();
  }

  filterByAppType(event: MatSelectChange): void {
    this.selectedAppType = event.value;
    this.filterApplicationList();
  }

  filterByAppCategory(event: MatSelectChange): void {
    this.selectedAppCategory = event.value;
    this.filterApplicationList();
  }

  filterByAppName(filterValue: string): void {
    this.searchKey = filterValue.toLowerCase().trim();
    this.filterApplicationList();
  }

  private filterApplicationList(): void {
    this.filteredApps = _.filter(this.solutionApps, matdata => {
      return (this.selectedAppCategory.value === 'all' || _.contains(matdata.categories, this.selectedAppCategory.value))
        && (this.selectedAppType.value === 'all' || _.contains(matdata.types, this.selectedAppType.value))
        && (matdata.name.toLowerCase().includes(this.searchKey.toLowerCase().trim()));
    });
    console.log('filteredApps', this.filteredApps);
  }

  private loadMatCardDataConfig(): void {
    this.portalService.getSolutionApps().subscribe(appList => {
      this.solutionApps = new Array<Application>()
      if (appList) {
        const lthis = this;
        _.each(appList, function (app) {
          lthis.solutionApps.push(_.extend(new Application(), app));
        });
      }
      console.log('loadMatCardDataConfig:::', this.solutionApps);
      this.filteredApps = this.solutionApps;
    });
  }

  navigateTo(app: AppType): void {
    window.open(app.url, '_new');
  }

}
