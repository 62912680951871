export class MenuItem {
  id: number;
  name: string;
  label: string;
  action: string;
  styleClass: string;
  iconClass: string;
  title: string;
  iconClassForTitleMenu: string;
  iconTip: string;
  enabled: boolean;
  role: string;

  constructor(id: number, name: string, label: string, action: string, styleClass?: string, iconClass?: string,
              title?: string, iconTip?: string, role?: string, enabled: boolean = true) {
    this.id = id;
    this.name = name;
    this.title = title;
    this.label = label;
    this.action = action;
    this.styleClass = styleClass;
    this.iconClass = iconClass;
    this.iconTip = iconTip;
    this.enabled = enabled;
    this.role = role || 'USER';
  }

  static create(id: number, name: string, label: string, action: string, styleClass?: string, iconClass?: string,
                title?: string, iconTip?: string, role?: string, enabled: boolean = true): MenuItem {
    return new MenuItem(id, name, label, action, styleClass, iconClass, title, iconTip, role, enabled);
  }
}
