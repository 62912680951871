<mat-card fxFlex="100" fxLayout="row wrap" class="widget">
  <div fxFlex="100" fxLayoutAlign="center center" class="widget-summary">

  </div>
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-around start">
    <div fxLayout="column" fxFlex="20" fxLayoutAlign="space-between stretch">
      <div fxLayout="row" fxLayoutAlign="center center">
        <p class="font-weight-bold">{{solarAssets[5].source}}</p>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center">
        <uilib-chartgauge-tile fxLayout="row" #solarPoint6 class="" [widget]="SolarWidget6"></uilib-chartgauge-tile>
        <uilib-chartgauge-tile fxLayout="row" #homePoint6 class="" [widget]="HomeWidget6"></uilib-chartgauge-tile>
      </div>
    </div>

    <div fxLayout="column" fxFlex="20" fxLayoutAlign="space-between stretch">
      <div fxLayout="row" fxLayoutAlign="center center">
        <p class="font-weight-bold">{{solarAssets[6].source}}</p>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center">
        <uilib-chartgauge-tile fxLayout="row" #solarPoint7 class="" [widget]="SolarWidget7"></uilib-chartgauge-tile>
        <uilib-chartgauge-tile fxLayout="row" #homePoint7 class="" [widget]="HomeWidget7"></uilib-chartgauge-tile>
      </div>
    </div>

    <div fxLayout="column" fxFlex="20" fxLayoutAlign="space-between stretch">
      <div fxLayout="row" fxLayoutAlign="center center">
        <p class="font-weight-bold">{{solarAssets[7].source}}</p>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center">
        <uilib-chartgauge-tile fxLayout="row" #solarPoint8 class="" [widget]="SolarWidget8"></uilib-chartgauge-tile>
        <uilib-chartgauge-tile fxLayout="row" #homePoint8 class="" [widget]="HomeWidget8"></uilib-chartgauge-tile>
      </div>
    </div>

    <div fxLayout="column" fxFlex="20" fxLayoutAlign="space-between stretch">
      <div fxLayout="row" fxLayoutAlign="center center">
        <p class="font-weight-bold">{{solarAssets[8].source}}</p>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center">
        <uilib-chartgauge-tile fxLayout="row" #solarPoint9 class="" [widget]="SolarWidget9"></uilib-chartgauge-tile>
        <uilib-chartgauge-tile fxLayout="row" #homePoint9 class="" [widget]="HomeWidget9"></uilib-chartgauge-tile>
      </div>
    </div>

    <div fxLayout="column" fxFlex="20" fxLayoutAlign="space-between stretch">
      <div fxLayout="row" fxLayoutAlign="center center">
        <p class="font-weight-bold">{{solarAssets[9].source}}</p>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center">
        <uilib-chartgauge-tile fxLayout="row" #solarPoint10 class="" [widget]="SolarWidget10"></uilib-chartgauge-tile>
        <uilib-chartgauge-tile fxLayout="row" #homePoint10 class="" [widget]="HomeWidget10"></uilib-chartgauge-tile>
      </div>
    </div>
  </div>


  <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between stretch">
    <div fxLayout="column" fxFlex="20" fxLayoutAlign="space-between center" fxLayoutGap="10px">

      <div fxLayout="column" fxLayoutAlign="space-between stretch">
        <div [fxLayout]="solarLayout" fxLayoutGap="5px">
          <uilib-chartgauge-tile fxLayout="row" #solarPoint1 class="" [widget]="SolarWidget1"></uilib-chartgauge-tile>
          <uilib-chartgauge-tile fxLayout="row" #homePoint1 class="" [widget]="HomeWidget1"></uilib-chartgauge-tile>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center">
          <p class="font-weight-bold">{{solarAssets[0].source}}</p>
        </div>
      </div>

      <div fxLayout="column" fxLayoutAlign="space-between stretch">
        <div [fxLayout]="solarLayout" fxLayoutGap="5px">
          <uilib-chartgauge-tile fxLayout="row" #solarPoint2 class="" [widget]="SolarWidget2"></uilib-chartgauge-tile>
          <uilib-chartgauge-tile fxLayout="row" #homePoint2 class="" [widget]="HomeWidget2"></uilib-chartgauge-tile>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center">
          <p class="font-weight-bold">{{solarAssets[1].source}}</p>
        </div>
      </div>

      <div fxLayout="column" fxLayoutAlign="space-between stretch">
        <div [fxLayout]="solarLayout" fxLayoutGap="5px">
          <uilib-chartgauge-tile fxLayout="row" #solarPoint3 class="" [widget]="SolarWidget3"></uilib-chartgauge-tile>
          <uilib-chartgauge-tile fxLayout="row" #homePoint3 class="" [widget]="HomeWidget3"></uilib-chartgauge-tile>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center">
          <p class="font-weight-bold">{{solarAssets[2].source}}</p>
        </div>
      </div>

      <div fxLayout="column" fxLayoutAlign="space-between stretch">
        <div [fxLayout]="solarLayout" fxLayoutGap="5px">
          <uilib-chartgauge-tile fxLayout="row" #solarPoint4 class="" [widget]="SolarWidget4"></uilib-chartgauge-tile>
          <uilib-chartgauge-tile fxLayout="row" #homePoint4 class="" [widget]="HomeWidget4"></uilib-chartgauge-tile>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center">
          <p class="font-weight-bold">{{solarAssets[3].source}}</p>
        </div>
      </div>

      <div fxLayout="column" fxLayoutAlign="space-between stretch">
        <div [fxLayout]="solarLayout" fxLayoutGap="5px">
          <uilib-chartgauge-tile fxLayout="row" #solarPoint5 class="" [widget]="SolarWidget5"></uilib-chartgauge-tile>
          <uilib-chartgauge-tile fxLayout="row" #homePoint5 class="" [widget]="HomeWidget5"></uilib-chartgauge-tile>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center">
          <p class="font-weight-bold">{{solarAssets[4].source}}</p>
        </div>
      </div>

    </div>
    <div fxLayout="column" fxFlex="70" fxLayoutAlign="start stretch">
      <div fxFill fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="100px">
        <div class="grid-box-r" #gridPointR>
          <uilib-chartgauge-tile [widget]="GridWidgetR" class=""></uilib-chartgauge-tile>
        </div>

        <div class="grid-box-d" #gridPointD>
          <uilib-chartgauge-tile [widget]="GridWidgetD" class=""></uilib-chartgauge-tile>
        </div>
      </div>
    </div>
    <div fxLayout="column" fxFlex="10" fxLayoutAlign="space-between center">
      <uilib-chartgauge-tile fxLayout="row" #evPoint1 class="" [widget]="EvWidget1"></uilib-chartgauge-tile>
      <uilib-chartgauge-tile fxLayout="row" #evPoint2 class="" [widget]="EvWidget2"></uilib-chartgauge-tile>
      <uilib-chartgauge-tile fxLayout="row" #evPoint3 class="" [widget]="EvWidget3"></uilib-chartgauge-tile>
      <uilib-chartgauge-tile fxLayout="row" #evPoint4 class="" [widget]="EvWidget4"></uilib-chartgauge-tile>
      <uilib-chartgauge-tile fxLayout="row" #evPoint5 class="" [widget]="EvWidget5"></uilib-chartgauge-tile>
    </div>
  </div>
</mat-card>
