<header fxLayout="row" fxFlex="100" fxLayoutAlign="space-between center" class="sticky-top-na">
  <mat-toolbar class="header-bar" color="primary" fxFlex="100">
    <mat-toolbar-row class="header-row px-0" fxLayout="row wrap" fxLayoutAlign="space-between center">
      <div fxLayout="row" fxLayoutAlign="start center" class="page-title">
        <div fxLayoutAlign="center center" class="image-box-container"><a class="image-box icon-l2l" href="/" title="L2L Open Data Platform"></a></div>
        <span class="font-weight-bold ml-3">Open
          Data Platform</span>
      </div>
      <div [ngClass.gt-sm]="'spacer'"></div>
      <div class="login-info mt-2" ngClass.lt-md="mb-2" fxFlex.lt-md="100" fxLayout="row" *ngIf="loggedUser" fxLayoutAlign="end center">
        <div fxLayout="row" fxLayoutAlign="end center">
          <mat-icon class="login-circle" [ngClass.lt-sm]="'is-mobile'">account_circle</mat-icon>
          <span class="ml-1">{{loggedUser? (loggedUser.fullName || loggedUser.emailId):'Guest'}}</span>
          <span class="ml-1" *ngIf="hasAdminRole">(<button mat-button [matMenuTriggerFor]="menu" class="menu-label p-1">{{LDC}}</button>)</span>
          <mat-menu #menu="matMenu">
            <button mat-menu-item *ngFor="let ldcId of ldcList" (click)="changeLDC(ldcId)">{{ldcId}}</button>
          </mat-menu>
          <!-- <button mat-icon-button class="icon-switch-account" *ngIf="hasAdminRole" [matTooltip]="'Switch account to view data'"
            (click)="viewAccounList()">
          </button> -->
        </div>
        <div [ngClass]="'pl-2'" [ngClass.lt-sm]="'pl-1'" fxLayoutAlign="end center">
          <button mat-button class="login-info text-danger" (click)="actionLogout()">
            Log Out
          </button>
        </div>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
</header>